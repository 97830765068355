import NewRelicBrowser from 'new-relic-browser'
import BaseBridge from 'src/config/bridge/BaseBridge'

import packageJson from '../../../package.json'

// A variável newrelic deve possuir o mesmo nome da variável definida no script do NewRelic
declare const newrelic: typeof NewRelicBrowser

const newrelicOn = true

class NewRelicUtils {
  static setCustomAttribute(name: string, value: string | number): void {
    if (newrelicOn && newrelic) {
      newrelic.setCustomAttribute(name, value)
    }
  }

  /**
   * Logs an error to New Relic with optional custom attributes.
   *
   * This method checks if New Relic is enabled (`newrelicOn` is `true`),
   * if the global `newrelic` object is available, and if the environment
   * variable `REACT_APP_NEW_RELIC_LOG` is set to `'true'`. If all conditions
   * are met, it logs the provided error along with any custom attributes
   * to New Relic.
   *
   * `errorCodeRef` must contain a string with the error code reference.
   * The code reference must be contains [Module or Class].[Action or Function]
   *
   * The `errorCodeRef` and `errorLogRef` properties are extracted from the error object.
   *
   * If the error does not contain an `errorCodeRef` property, additional
   * attributes such as the error stack and a `noErrorCodeRef` flag are
   * added to the custom attributes.
   *
   * @param {Error} error - The error object to be logged. It can optionally
   * include `errorCodeRef` and `errorLogRef` properties for additional context.
   * @param {{ [key: string]: string | number }} [customAttributes] - Optional
   * custom attributes to provide additional context about the error. These
   * attributes will be merged with default attributes like `errorCodeRef`
   * and `errorLogRef`.
   *
   * @example
   * try {
   *   throw new Error('Test error');
   * } catch (error) {
   *   NewRelicUtils.noticeError(error, {
   *     errorCodeRef: UseModule.userLogin
   *     module: 'UserModule',
   *     action: 'Login',
   *     userId: 12345,
   *   });
   * }
   */
  static noticeError(error: Error, customAttributes?: { [key: string]: string | number }) {
    if (newrelicOn && newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      const { errorCodeRef, errorLogRef } = error as unknown as {
        errorCodeRef: string
        errorLogRef: string
      }
      const attributes = { errorCodeRef, errorLogRef, ...customAttributes }

      if (!errorCodeRef) {
        const errorStack = error?.stack ?? 'NoErrorStack'
        const noErrorCodeRef = 'NoErrorCodeRef'
        const additionalAttributes = { errorStack, noErrorCodeRef }
        Object.assign(attributes, additionalAttributes)
      }
      newrelic.noticeError(error, attributes)
    }
  }

  static async setInitSettings() {
    if (newrelicOn && newrelic && process.env.REACT_APP_NEW_RELIC_LOG === 'true') {
      const spaVersion = packageJson?.version || 'spaVersion não encontrada'
      NewRelicUtils.setCustomAttribute('spaVersion', spaVersion)

      try {
        const appInfo = await BaseBridge.getAppInfo()

        const language = appInfo.language || 'Idioma não encontrado'
        const application = appInfo.application || 'Aplicação não encontrada'
        const organization = appInfo.organization || 'Organização não encontrada'
        const superAppVersion = appInfo.appVersion || 'Versão dp app não encontrada'

        NewRelicUtils.setCustomAttribute('language', language)
        NewRelicUtils.setCustomAttribute('deviceType', application)
        NewRelicUtils.setCustomAttribute('deviceName', organization)
        NewRelicUtils.setCustomAttribute('superAppVersion', superAppVersion)
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'NewRelicUtils.setInitSettings.BaseBridge.getAppInfo',
        })
      }

      try {
        const userInfo = await BaseBridge.getUserInfo()

        NewRelicUtils.setCustomAttribute(
          'userAccount',
          userInfo.account || 'Conta do usuário não encontrada',
        )
      } catch (error) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'NewRelicUtils.setInitSettings.BaseBridge.getUserInfo',
        })
      }
    }
  }
}

export default NewRelicUtils
