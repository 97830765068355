import BaseBridge from 'src/config/bridge/BaseBridge'
import { TagEventsEnum } from 'src/enums/TagEvents'

export const AnalyticsReceiptAccess = async (
  name: string,
  params: Record<string, string>,
  handleError: (error: unknown, context: string) => void,
) => {
  try {
    await BaseBridge.requestAnalytics(name, {
      ...params,
      ref_figma: '9',
      screen: 'Comprovante',
      action_id: 'Acessou a tela de comprovante',
      ref_type: TagEventsEnum.REF_SCREEN,
      content_action: TagEventsEnum.ACTION_LOAD_DATA,
    })
  } catch (error) {
    handleError(error, 'Receipt.AnalyticsReceiptAccess.BaseBridge.requestAnalytics')
  }
}
