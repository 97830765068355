import { SkeletonBox, SkeletonContent } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadCardsVip = () => (
  <SkeletonBox padding="16px" style={{ marginBottom: '0px' }} data-testid="loading-cards-vip">
    <SkeletonContent>
      <InterUISkeleton height="32px" width="32px" marginBottom="4px" />
      <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
      <InterUISkeleton height="16px" width="70%" marginBottom="16px" />
      <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
      <InterUISkeleton height="56px" width="100%" marginBottom="16px" />
      <InterUISkeleton height="32px" width="100%" marginBottom="0px" />
    </SkeletonContent>
  </SkeletonBox>
)
