import BaseBridge from 'src/config/bridge/BaseBridge'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { InterUIBabi, InterUIContainer } from '@interco/inter-ui-react-lib'

interface BabiProps {
  margin?: string
}

export const Babi = ({ margin = '24px' }: BabiProps) => {
  const handlerError = useLocalErrorHandler()

  const requestBabi = async () => {
    try {
      await BaseBridge.requestBabi()
    } catch (error) {
      handlerError(error, 'Babi.BaseBridge.requestBabi')
    }
  }
  return (
    <InterUIContainer margin={margin}>
      <InterUIBabi onClick={requestBabi} />
    </InterUIContainer>
  )
}
