export enum TagEventsEnum {
  // Consortium
  PRODUCT = 'Consórcio',
  CONSORTIUM_ACCESS = 'CONSOR_HOME_DC_ACESSO',
  CONSORTIUM_RESUME = 'CONSOR_RESUMO_DC_ACESSO',
  CONSORTIUM_SUCCESS = 'CONSOR_SUCESSO',
  CONSORTIUM_FLOW = 'CONSOR_FLUXO',
  CONSORTIUM_OFFERS = 'CONSOR_OFERTA_ACESSO',
  CONSORTIUM_OFFERS_FLOW = 'CONSOR_OFERTA_FLUXO',
  CONSORTIUM_BANNER = 'CONSOR_BANNER_DISPLAY',
  MANUAL = 'MANUAL_PRODUTO',

  // Actions
  ACTION_LOAD_DATA = 'Dado carregado',
  ACTION_CLICK = 'Clique',
  ACTION_HOME_INSURANCE = 'Voltar para a home de seguros',

  // REF TYPE
  REF_SCREEN = 'Tela',
  REF_BUTTON = 'Botão',
}
