import { IModality } from 'src/store/ducks/hiring/types'

import { uuid } from '@interco/lib-util'

import { Item } from './Item/Item'
import { Grid } from './Modalities.styles'

interface IModalitiesProps {
  modalities: IModality[]
  selectedItem: (modality: IModality) => void
}

export const Modalities = ({ modalities, selectedItem }: IModalitiesProps) => (
  <Grid>
    {modalities?.map(({ ...props }) => (
      <Item key={uuid()} selectedItem={selectedItem} {...props} />
    ))}
  </Grid>
)
