import { Carousel } from '@interco/inter-ui/components/Carousel'
import { uuid } from '@interco/lib-util'

import { Container } from './OffersCarousel.styles'
import { CardVip } from '../../../../components/CardVip/CardVip'
import { IOffersCarouselProps } from './OffersCarousel.types'

export const OffersCarousel = ({ offers, productId, onHandleClick }: IOffersCarouselProps) => {
  const offersSlider = offers.map((offer, index) => (
    <CardVip
      key={uuid()}
      cardDetails={{
        cardId: offer.cardId,
        cardTitle: offer.cardTitle,
        consortiumValue: offer.consortiumValue,
        installmentValue: offer.installmentValue,
        highlightCard: offer.highlightCard,
        isVip: offer.isVip,
        term: offer.term,
        productId,
      }}
      onClickedDetail={() => onHandleClick({ ...offer, productId })}
      data-testid={`card-vip-${index}`}
    />
  ))

  return (
    <Container>
      <Carousel
        dots
        arrows={false}
        infinite={false}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        dotsType="medium"
        verticalMode={false}
        className="slider"
      >
        {offersSlider}
      </Carousel>
    </Container>
  )
}
