import { useEffect, useState } from 'react'

import { parse, differenceInSeconds, addSeconds } from 'date-fns'
import { Icons } from 'src/utils/icons'
import { SIZE_SM } from 'src/utils/constants'
import { useTheme } from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

import {
  ContentCountDownTimer,
  VerticalSeparatorLine,
  ColumnCountDownTimer,
} from './CountDownTimer.style'

interface CountdownTimerProps {
  endDate: string
  serverNowDate: string
}

export const CountdownTimer = ({ endDate, serverNowDate }: CountdownTimerProps) => {
  const theme = useTheme()
  const white = theme.colors.theme
  const timeTitle = 'Oferta relâmpago'
  const timeLabel = 'Termina em'
  const parseDate = (dateString: string) => parse(dateString, 'dd/MM/yyyy HH:mm:ss', new Date())

  const initialNow = parseDate(serverNowDate)
  const end = parseDate(endDate)

  const calculateTimeLeft = (now: Date) => {
    const difference = differenceInSeconds(end, now)

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }
    }

    return {
      days: Math.floor(difference / 86400),
      hours: Math.floor((difference % 86400) / 3600),
      minutes: Math.floor((difference % 3600) / 60),
      seconds: difference % 60,
    }
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(initialNow))
  const [currentNow, setCurrentNow] = useState(initialNow)

  useEffect(() => {
    const timer = setTimeout(() => {
      const newNow = addSeconds(currentNow, 1)
      setCurrentNow(newNow)
      setTimeLeft(calculateTimeLeft(newNow))
    }, 1000)

    return () => clearTimeout(timer)
  })

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time)

  return (
    <ContentCountDownTimer>
      <ColumnCountDownTimer gap="8px">
        <Icons.DeliveryTime height={SIZE_SM} width={SIZE_SM} color={white} />
        <Text variant="caption-1" bold>
          {timeTitle}
        </Text>
      </ColumnCountDownTimer>
      <VerticalSeparatorLine />
      <ColumnCountDownTimer gap="4px">
        <Text variant="caption-1">{timeLabel}</Text>
        {timeLeft.days > 0 ? (
          <Text variant="caption-1" bold>
            {timeLeft.days} {timeLeft.days > 1 ? 'dias' : 'dia'}
          </Text>
        ) : (
          <Text variant="caption-1" bold style={{ minWidth: '62px' }}>
            {formatTime(timeLeft.hours)}:{formatTime(timeLeft.minutes)}:
            {formatTime(timeLeft.seconds)}
          </Text>
        )}
      </ColumnCountDownTimer>
    </ContentCountDownTimer>
  )
}
