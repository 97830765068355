import BaseService from 'src/config/bridge/BaseService'
import {
  IConsortiumDetails,
  IExtract,
  IMyConsortia,
  IPaidInstallments,
} from 'src/Interfaces/afterSales'
import { ILateInstallments } from 'src/store/ducks/afterSales/types'

import { MockService } from './mockService'

export default class AfterSalesService {
  static getMyConsortia() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IMyConsortia>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/multi-consortium`,
      headers,
    })
  }

  static getConsortiumDetails(bankAccount: string, consortiumId: string, status: string) {
    const headers = {
      bankAccount,
      consortiumId,
      status,
    }

    return BaseService.doExecute<IConsortiumDetails>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/consortium-details`,
      headers,
    })
  }

  static getInstallmentsExtract(payload: ILateInstallments) {
    const { consortiumId, bankAccount } = payload

    const headers = {
      consortiumId: consortiumId || '',
      bankAccount: bankAccount || '',
    }

    return BaseService.doExecute<IExtract[]>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/installments-extract`,
      headers,
    })
  }

  static getPaidInstallments(bankAccount: string, consortiumId: string, installmentNumber: string) {
    const headers = {
      bankAccount,
      consortiumId,
      installmentNumber,
    }

    return BaseService.doExecute<IPaidInstallments>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/paid-installments`,
      headers,
    })
  }
}
