import styled from 'styled-components'

export const ContentCountDownTimer = styled.div`
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A500};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  span {
    color: ${(props) => props.theme.colors.theme};
  }
`

export const ColumnCountDownTimer = styled.div<{ gap: string }>`
  display: flex;
  gap: ${({ gap }) => gap};
`

export const VerticalSeparatorLine = styled.div`
  height: 16px;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.neutral.grayscale.A400};
`
