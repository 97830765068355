import styled from 'styled-components'

export const FaqContent = styled.div`
  display: flex;
  column-gap: 16px;

  svg {
    flex: none;
  }
`
