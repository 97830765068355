import BaseBridge from 'src/config/bridge/BaseBridge'
import { TagEventsEnum } from 'src/enums/TagEvents'

export const AnalyticsCarouselClick = async (
  name: string,
  params: Record<string, string>,
  handleError: (error: unknown, context: string) => void,
) => {
  try {
    await BaseBridge.requestAnalytics(name, {
      ...params,
      screen: 'Home do produto',
      ref_type: TagEventsEnum.REF_BUTTON,
      content_action: TagEventsEnum.ACTION_CLICK,
    })
  } catch (error) {
    handleError(error, 'Carousel.AnalyticsCarouselClick.BaseBridge.requestAnalytics')
  }
}
