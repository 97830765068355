import styled from 'styled-components'

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A200};
  border-radius: 12px;
  overflow: hidden;
`

export const HeaderCard = styled.div`
  padding: 16px;
`

export const BodyCard = styled.div`
  padding: 16px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
  box-sizing: border-box;

  button {
    min-width: auto;

    p {
      font-size: 12px;
    }
  }
`
