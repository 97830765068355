import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'

import InterWebviewBridge, {
  interWbNavigate,
  interWbSession,
  IWbTac,
  IWbTic,
  WbSessionActions,
} from '@interco/inter-webview-bridge'

import { BridgeMockAppInfo, BridgeMockUserInfo } from './BridgeMock'

export class BaseBridge {
  private static backNavigationHandler: (() => void) | null = null

  private static handleError(error: unknown, errorCodeRef: string): void {
    NewRelicUtils.noticeError(error as Error, { errorCodeRef })
  }

  static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://'
    const HTTP_PROTOCOL = 'http://'
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url
    }
    return HTTPS_PROTOCOL + url
  }

  static isBrowser(): boolean {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'BROWSER'
  }

  static isIOS() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'IOS'
  }

  static isAndroid() {
    const iWb = InterWebviewBridge.getInstance()

    if (process.env.REACT_APP_BRIDGE_DEBUG === 'true') {
      iWb.setDebug(true)
    }

    return iWb.getEnvironment() === 'ANDROID'
  }

  static async openDeepLink(deepLink: string) {
    if (!BaseBridge.isBrowser()) {
      try {
        await interWbNavigate.openDeepLink(deepLink)
      } catch (error) {
        this.handleError(error, 'BaseBridge.openDeepLink')
      }
    }
  }

  static async addBackListener(event: () => void) {
    if (!BaseBridge.isBrowser()) {
      try {
        const iWb = InterWebviewBridge.getInstance()

        if (BaseBridge.backNavigationHandler) {
          iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, BaseBridge.backNavigationHandler)
        }

        iWb.addWebviewEventListener(WbSessionActions.GO_BACK, event)

        BaseBridge.backNavigationHandler = event
      } catch (error) {
        this.handleError(error, 'BaseBridge.addBackListener')
      }
    }
  }

  static async requestGoBack() {
    if (!BaseBridge.isBrowser()) {
      try {
        await interWbNavigate.requestGoBack()
      } catch (error) {
        this.handleError(error, 'BaseBridge.requestGoBack')
      }
    }
  }

  static async requestBabi() {
    if (!BaseBridge.isBrowser()) {
      try {
        interWbNavigate.requestBabi()
      } catch (error) {
        this.handleError(error, 'BaseBridge.requestBabi')
      }
    }
  }

  static async getUserInfo() {
    let userInfo

    if (BaseBridge.isBrowser()) {
      userInfo = BridgeMockUserInfo
    } else {
      userInfo = await interWbSession.getUserInfo()
    }

    return userInfo
  }

  static async getAppInfo() {
    let appInfo

    if (BaseBridge.isBrowser()) {
      appInfo = BridgeMockAppInfo
    } else {
      appInfo = await interWbSession.getAppInfo()
    }

    return appInfo
  }

  static async openLinkOnBrowser(link: string) {
    if (BaseBridge.isBrowser()) {
      return window.open(link, '_blank', 'noopener,noreferrer')
    }

    try {
      return await interWbNavigate.openNativeBrowser(link)
    } catch (error) {
      this.handleError(error, 'BaseBridge.openLinkOnBrowser')
      return Promise.reject(error)
    }
  }

  static async openWhatsApp(phone: string) {
    try {
      return await BaseBridge.openLinkOnBrowser(`https://wa.me/${phone}`)
    } catch (error) {
      this.handleError(error, 'BaseBridge.openWhatsApp')
      return Promise.reject(error)
    }
  }

  static async callPhoneNumber(phoneNumber: string) {
    if (!BaseBridge.isBrowser()) {
      try {
        await interWbNavigate.callPhoneNumber(phoneNumber)
      } catch (error) {
        this.handleError(error, 'BaseBridge.callPhoneNumber')
      }
    }
  }

  static async requestAnalytics(name: string, params?: Record<string, string>) {
    if (!BaseBridge.isBrowser()) {
      try {
        await interWbSession.requestAnalytics(name, params)
      } catch (error) {
        this.handleError(error, 'BaseBridge.requestAnalytics')
      }
    } else {
      // eslint-disable-next-line no-console
      // console.log('nome do evento: ', name, 'Params: ', params !== undefined ? params : '')
    }
  }

  static async openPdf(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(this.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (error) {
      this.handleError(error, 'BaseBridge.openPdf')
    }
  }

  static async ticWb(ticProps: IWbTic, caller: string): Promise<void> {
    if (!this.isBrowser()) {
      try {
        await interWbSession.tic(ticProps)
      } catch (error) {
        this.handleError(error, `${caller}.BridgeService.ticWb`)
      }
    }
  }

  static async tacWb(tacProps: IWbTac, caller: string): Promise<void> {
    if (!this.isBrowser()) {
      try {
        await interWbSession.tac(tacProps)
      } catch (error) {
        this.handleError(error, `${caller}.BridgeService.tacWb`)
      }
    }
  }
}

export default BaseBridge
