import {
  IConsortiumDetails,
  IConsortiumDetailsRequest,
  IExtract,
  IExtractTransaction,
  IMyConsortia,
  IPaidInstallments,
  IPaidInstallmentsRequest,
} from 'src/Interfaces/afterSales'
import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { SagaWithHistory } from '../utils/providerSaga'
import { AfterSalesTypes, ILateInstallments } from './types'

const getMyConsortiaRequest = (data?: SagaWithHistory) =>
  action(AfterSalesTypes.GET_MY_CONSORTIA_REQUEST, data)

const getMyConsortia = (state: RootState) => state.afterSales.myConsortia

const setMyConsortia = (data: IMyConsortia) => action(AfterSalesTypes.SET_MY_CONSORTIA, data)

const getSelectedConsortiumId = (state: RootState) => state.afterSales.selectedConsortiumId

const setSelectedConsortiumId = (data: string) =>
  action(AfterSalesTypes.SET_SELECTED_CONSORTIUM_ID, data)

const getConsortiumDetailsRequest = (data: IConsortiumDetailsRequest) =>
  action(AfterSalesTypes.GET_CONSORTIUM_DETAILS_REQUEST, data)

const getConsortiumDetails = (state: RootState) => state.afterSales.consortiumDetails

const setConsortiumDetails = (data: IConsortiumDetails) =>
  action(AfterSalesTypes.SET_CONSORTIUM_DETAILS, data)

const getConsortiumExtractRequest = (data?: ILateInstallments) =>
  action(AfterSalesTypes.GET_CONSORTIUM_EXTRACT_REQUEST, data)

const getConsortiumExtract = (state: RootState) => state.afterSales.consortiumExtract

const setConsortiumExtract = (data: IExtract[]) =>
  action(AfterSalesTypes.SET_CONSORTIUM_EXTRACT, data)

const getPaidInstallmentsRequest = (data: IPaidInstallmentsRequest) =>
  action(AfterSalesTypes.GET_PAID_INSTALLMENTS_REQUEST, data)

const getPaidInstallments = (state: RootState) => state.afterSales.paidInstallments

const setPaidInstallments = (data: IPaidInstallments) =>
  action(AfterSalesTypes.SET_PAID_INSTALLMENTS, data)

const getLateInstallments = (state: RootState) => state.afterSales.lateInstallments

const setLateInstallments = (data: IExtractTransaction[]) =>
  action(AfterSalesTypes.SET_LATE_INSTALLMENTS, data)

export const AfterSalesActions = {
  getMyConsortia,
  setMyConsortia,
  getMyConsortiaRequest,
  getSelectedConsortiumId,
  setSelectedConsortiumId,
  getConsortiumDetails,
  setConsortiumDetails,
  getConsortiumDetailsRequest,
  getConsortiumExtract,
  setConsortiumExtract,
  getConsortiumExtractRequest,
  getPaidInstallmentsRequest,
  getPaidInstallments,
  setPaidInstallments,
  getLateInstallments,
  setLateInstallments,
}
