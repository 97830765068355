import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IGroup } from 'src/Interfaces/afterSales'
import { Skeleton } from 'src/components'
import { ConsortiumStatus } from 'src/enums/ConsortiumStatus'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { ContainerFullPage } from 'src/styles/commons'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import BaseBridge from 'src/config/bridge/BaseBridge'

import { ProgressGroup } from './ProgressGroup/ProgressGroup'

export const MyConsortia = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const isLoading = useSelector(LoadingActions.get)
  const myConsortia = useSelector(AfterSalesActions.getMyConsortia)

  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)

  const handleBackButton = () => {
    if (accessViaDeepLink) {
      dispatch(
        HiringActions.getHomeRequest({
          history,
          pathname: TypesRoutes.HOME,
        }),
      )
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.MY_CONSORTIA,
    backNavigationHandler: handleBackButton,
  })

  const handleSelectedConsortium = (consortiumId: string, status: string) => {
    const isAnalysisOrRefused =
      status === ConsortiumStatus.ANALYSIS || status === ConsortiumStatus.REFUSED

    if (isAnalysisOrRefused) {
      dispatch(
        HiringActions.getReceiptRequest({
          proposalUuid: consortiumId,
        }),
      )
      history.push(TypesRoutes.RECEIPT)

      BaseBridge.ticWb(
        {
          name: 'btn_my_card_analysis_or_refused',
          module: 'consortium',
          action: 'open_receipt_screen_my_card_analysis_or_refused',
          params: {
            proposalUuid: consortiumId,
            status: JSON.stringify(status),
          },
        },
        'MyConsortia.handleSelectedConsortium.AnalysisOrRefused',
      )
    } else {
      dispatch(
        AfterSalesActions.getConsortiumDetailsRequest({
          history,
          pathname: TypesRoutes.MY_CONSORTIA_DETAIL,
          consortiumId,
          status: status.toUpperCase(),
        }),
      )
      BaseBridge.ticWb(
        {
          name: 'btn_my_card_others_status',
          module: 'consortium',
          action: 'open_my_consortia_detail_screen',
          params: {
            proposalUuid: consortiumId,
            status: JSON.stringify(status),
          },
        },
        'MyConsortia.handleSelectedConsortium',
      )
    }
  }

  useEffect(() => {
    if (myConsortia) {
      BaseBridge.tacWb(
        {
          name: 'screen_my_consortia',
          module: 'consortium',
          actions: ['open_my_consortia_screen'],
          params: {
            myConsortia: JSON.stringify(myConsortia),
          },
        },
        'MyConsortia',
      )
    }
  }, [])

  const renderMyConsortia = () => {
    const myConsortiaKeys = Object.keys(myConsortia)

    const ret = myConsortiaKeys.map((key) => {
      const keyType = key as keyof typeof myConsortia
      const group = myConsortia[keyType] as IGroup[]

      return (
        <ProgressGroup
          key={key}
          category={key}
          groups={group}
          selectedConsortium={(consortiumId: string) => handleSelectedConsortium(consortiumId, key)}
        />
      )
    })
    return ret
  }

  return isLoading ? (
    <ContainerFullPage height="0px" overflowHidden>
      <Skeleton.LoadMyConsortia />
    </ContainerFullPage>
  ) : (
    <ContainerFullPage margin="24px" height="0px">
      {renderMyConsortia()}
    </ContainerFullPage>
  )
}
