import BaseBridge from 'src/config/bridge/BaseBridge'
import { TagEventsEnum } from 'src/enums/TagEvents'

const sendAnalytics = async (
  name: string,
  params: Record<string, string>,
  eventError: string,
  handleError: (error: unknown, context: string) => void,
) => {
  try {
    await BaseBridge.requestAnalytics(name, params)
  } catch (error) {
    handleError(error, `Checkout.${eventError}.BaseBridge.requestAnalytics`)
  }
}

export const AnalyticsExclusiveOffersAccess = async (
  name: string,
  params: Record<string, string>,
  handleError: (error: unknown, context: string) => void,
) => {
  const paramsAccess = {
    ...params,
    ref_figma: '10',
    screen: 'Ofertas Exclusivas',
    action_id: 'Acessou a tela de ofertas exclusivas de consórcios',
    ref_type: TagEventsEnum.REF_SCREEN,
    content_action: TagEventsEnum.ACTION_LOAD_DATA,
  }

  sendAnalytics(name, paramsAccess, 'AnalyticsExclusiveOffersAccess', handleError)
}

export const AnalyticsExclusiveOffersClick = async (
  name: string,
  params: Record<string, string>,
  handleError: (error: unknown, context: string) => void,
) => {
  const paramsClick = {
    ...params,
    ref_figma: '11',
    screen: 'Ofertas Exclusivas',
    action_id: 'Contratar',
    ref_type: TagEventsEnum.REF_BUTTON,
    content_action: TagEventsEnum.ACTION_CLICK,
  }

  sendAnalytics(name, paramsClick, 'AnalyticsExclusiveOffersClick', handleError)
}
