import styled from 'styled-components'

export const ContentLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.neutral.grayscale.A200};
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 24px;

    &:active,
    &:hover:enabled {
      background-color: transparent;
      color: var(--button-link-text-color);
    }
  }
`

export const CheckoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 32px 24px;
  background-color: var(--gray100);

  button {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
`
