import { useEffect, useLayoutEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'
import { initTheme, Theme } from '@interco/inter-ui'

import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ThemesEnum } from './enums/ThemesEnum'
import { ErrorView } from './pages/shared/Error/ErrorView'
import Routes from './routes'
import { ErrorActions } from './store/ducks/error/actions'
import { NewRelicActions } from './store/ducks/newRelic/actions'
import { useLocalErrorHandler } from './hooks/useLocalErrorHandler'

const App = () => {
  const dispatch = useDispatch()
  const handlerError = useLocalErrorHandler()

  InterUIFonts()

  const [theme, setTheme] = useState<DefaultTheme>(InterTheme(ThemesEnum.LIGHT))

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  const chooseTheme = async () => {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: false,
    }

    try {
      const [userInfo, appInfo] = await Promise.all([
        BaseBridge.getUserInfo(),
        BaseBridge.getAppInfo(),
      ])

      const { segmentation } = userInfo
      dispatch(NewRelicActions.setUserSegmentation(segmentation))

      const { isDarkMode, application, appVersion } = appInfo
      initTheme(Theme.PF, isDarkMode)
      isDarkMode ? setTheme(InterTheme(ThemesEnum.DARK)) : setTheme(InterTheme(ThemesEnum.LIGHT))

      dispatch(NewRelicActions.setUserContext(application))
      dispatch(NewRelicActions.setUserAppVersion(appVersion))
    } catch (error) {
      handlerError(error, 'App.chooseTheme.BaseBridge.getUserInfo.BaseBridge.getAppInfo')
      dispatch(ErrorActions.show(errorDetails))
    }
  }

  return (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <BrowserRouter>
          <ErrorView />
          <Navbar />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )
  )
}

export default App
