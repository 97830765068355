import BaseBridge from 'src/config/bridge/BaseBridge'
import { TagEventsEnum } from 'src/enums/TagEvents'

export const AnalyticsManualClick = async (
  name: string,
  params: Record<string, string>,
  handleError: (error: unknown, context: string) => void,
) => {
  try {
    await BaseBridge.requestAnalytics(name, {
      ...params,
      ref_figma: '10',
      action_id: 'manual',
      ref_type: TagEventsEnum.REF_BUTTON,
      content_action: TagEventsEnum.ACTION_CLICK,
      product: TagEventsEnum.PRODUCT,
    })
  } catch (error) {
    handleError(error, 'Manual.AnalyticsManualClick.BaseBridge.requestAnalytics')
  }
}
