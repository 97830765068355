import { useCallback } from 'react'

import NewRelicUtils from 'src/config/monitoring/NewRelicUtils'

export const useLocalErrorHandler = () => {
  const handlerError = useCallback((error: unknown, errorCodeRef: string) => {
    NewRelicUtils.noticeError(error as Error, { errorCodeRef })
  }, [])

  return handlerError
}
