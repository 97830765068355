import styled from 'styled-components'

export const ContentSlider = styled.div`
  .slider {
    .slick-slider {
      .slick-track {
        display: flex;
      }

      .slick-slide {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }
      }

      .slick-dots {
        bottom: -28px;

        li {
          opacity: 1;
          transition: all 0.3s linear;
          background: ${(props) => props?.theme?.colors?.primary?.A200};
        }
      }
    }
  }
`
export const BannerImage = styled.div`
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
`
