import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { QuickAccessBarEnum } from 'src/enums/QuickAccessBar'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { P, PXSmall } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { MANUAL_URL } from 'src/components/Manual/Manual.types'
import { AnalyticsManualClick } from 'src/components/Manual/Manual.tags'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { ActionsIcons, ContentIcons, GroupIcons, QtdExtract } from './QuickAccessBar.style'
import {
  BTN_EXTRACT_MY_CONSORTIA_DETAIL,
  BTN_PROPOSAL_MY_CONSORTIA_DETAIL,
  IQuickAccessBar,
  IQuickAccessProps,
  OPEN_EXTRACT_SCREEN,
  OPEN_PROPOSAL_SCREEN,
} from './QuickAccessBar.types'

export const QuickAccessBar = ({ quickAcessBar }: IQuickAccessProps) => {
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()
  const handlerError = useLocalErrorHandler()

  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)

  const colorPrimary = theme.colors.primary.A500
  const colorTheme = theme.colors.theme

  const moreLinksIcons = {
    iconPdf: <Icons.PdfFile width={SIZE_MD} height={SIZE_MD} color={colorPrimary} />,
    iconReceipt: <Icons.Receipt width={SIZE_MD} height={SIZE_MD} color={colorPrimary} />,
  }

  const quickAccessBar: IQuickAccessBar[] = [
    {
      id: 1,
      icon: moreLinksIcons.iconReceipt,
      description: QuickAccessBarEnum.EXTRACT,
      qtdExtracts: quickAcessBar?.paymentStatementQuantity ?? 0,
    },
    {
      id: 2,
      icon: moreLinksIcons.iconPdf,
      description: QuickAccessBarEnum.MANUAL,
      qtdExtracts: 0,
      label: (
        <>
          Manual
          <br /> do Produto
        </>
      ),
    },
    {
      id: 3,
      icon: moreLinksIcons.iconPdf,
      description: QuickAccessBarEnum.PROPOSAL,
      qtdExtracts: 0,
    },
  ]

  const logAction = async (name: string, action: string, params: Record<string, unknown>) => {
    try {
      await BaseBridge.ticWb(
        {
          name,
          module: 'consortium',
          action,
          params,
        },
        'QuickAccessBar.logAction',
      )
    } catch (error) {
      handlerError(error, 'QuickAccessBar.logAction.handlerError')
    }
  }

  const handlerManual = async () => {
    AnalyticsManualClick(
      TagEventsEnum.MANUAL,
      {
        screen: 'MyConsortiaDetail',
        account_context,
        segment,
      },
      handlerError,
    )

    try {
      await BaseBridge.openPdf(MANUAL_URL, 'Manual do produto')
    } catch (error) {
      handlerError(error, 'QuickAccessBar.handleManual.BaseBridge.openPdf')
    }
  }

  const handleClickQuickAccessBar = (item: QuickAccessBarEnum) => {
    if (item === QuickAccessBarEnum.PROPOSAL) {
      logAction(BTN_PROPOSAL_MY_CONSORTIA_DETAIL, OPEN_PROPOSAL_SCREEN, {
        quickAcessBar: JSON.stringify(quickAcessBar),
      })
      history.push(TypesRoutes.PROPOSAL)
    } else if (item === QuickAccessBarEnum.EXTRACT) {
      logAction(BTN_EXTRACT_MY_CONSORTIA_DETAIL, OPEN_EXTRACT_SCREEN, {
        quickAcessBar: JSON.stringify(quickAcessBar),
      })
      dispatch(
        AfterSalesActions.getConsortiumExtractRequest({
          history,
          pathname: TypesRoutes.EXTRACT,
        }),
      )
    }
    if (item === QuickAccessBarEnum.MANUAL) {
      handlerManual()
    }
  }

  const renderQuickAccessBar = quickAccessBar.map((item) => (
    <ActionsIcons
      key={item.id}
      data-testid={`test-links-${item.id}`}
      onClick={() => handleClickQuickAccessBar(item.description as QuickAccessBarEnum)}
    >
      <ContentIcons>{item.icon}</ContentIcons>
      <PXSmall bold margin="8px 0 0" textAlign="center">
        {item?.label ? item.label : item.description}
      </PXSmall>
      {item.qtdExtracts !== 0 && (
        <QtdExtract>
          <P marginBottom="0px" fontSize="14px" lineHeight="0px" color={colorTheme} bold>
            {item.qtdExtracts}
          </P>
        </QtdExtract>
      )}
    </ActionsIcons>
  ))

  return <GroupIcons>{renderQuickAccessBar}</GroupIcons>
}
