import { Fragment, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { UrlActions } from 'src/store/ducks/aboutUrl/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { ICard } from 'src/store/ducks/hiring/types'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { ContainerFullPage, H1, H3, PMedium } from 'src/styles/commons'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { productEnum } from 'src/enums/ProductId'
import { CountdownTimer } from 'src/components'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { uuid } from '@interco/lib-util'
import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { OffersCarousel } from './OffersCarousel/OffersCarousel'
import {
  AnalyticsExclusiveOffersAccess,
  AnalyticsExclusiveOffersClick,
} from './ExclusiveOffers.tags'

export const ExclusiveOffers = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const handlerError = useLocalErrorHandler()

  const account_context = useSelector(NewRelicActions.getUserContext)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const { title, subtitle, serverDate, campaignEndDate, vipGroupedCardList } = useSelector(
    HiringActions.getVipCards,
  )

  useEffect(() => {
    dispatch(UrlActions.faq())

    AnalyticsExclusiveOffersAccess(
      TagEventsEnum.CONSORTIUM_OFFERS,
      {
        account_context,
        segment,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      },
      handlerError,
    )

    BaseBridge.tacWb(
      {
        name: 'screen_exclusive_offers',
        module: 'consortium',
        actions: ['open_exclusive_offers_screen_via_deeplink'],
        params: {
          segment,
          accountContext: account_context,
          title,
          subtitle,
          cards: JSON.stringify(vipGroupedCardList),
        },
      },
      'ExclusiveOffers',
    )
  }, [])

  const handleBackButton = () => {
    if (accessViaDeepLink) {
      dispatch(PathParamsActions.setAccessedViaDeepLink(false))
      history.replace(TypesRoutes.START)
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: handleBackButton,
  })

  const onHandleClickedCheckout = (card: ICard) => {
    AnalyticsExclusiveOffersClick(
      TagEventsEnum.CONSORTIUM_OFFERS_FLOW,
      {
        account_context,
        segment,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
        action_id: card.cardTitle,
        modality: productEnum[card.productId],
        offer: `${card.offer}`,
        card_value: `${card.consortiumValue}` || 'Valor da carta não encontrado',
        installment_value: `${card.installmentValue}` || 'Valor da parcela não encontrado',
        vip: card.isVip ? 'Oferta exclusiva' : 'Oferta padrão',
      },
      handlerError,
    )

    BaseBridge.ticWb(
      {
        name: 'btn_exclusive_offers',
        module: 'consortium',
        action: 'open_screen_checkout',
        params: {
          modality: productEnum[card.productId],
          cardId: card.cardId,
          productId: card.productId,
          group: card.group,
          installmentValue: card.installmentValue,
          isVip: card.isVip,
        },
      },
      'ExclusiveOffers.onHandleClickedCheckout',
    )

    dispatch(
      HiringActions.getCheckoutRequest({
        history,
        pathname: TypesRoutes.CHECKOUT,
        cardId: card.cardId,
        productId: card.productId,
        group: card.group as number,
        installmentValue: card.installmentValue ?? 0,
        isVip: card.isVip,
      }),
    )
  }

  const renderVipCards = vipGroupedCardList?.map((vipCards) => (
    <Fragment key={uuid()}>
      <H3 marginBottom="16px" scale={500}>
        {productEnum[vipCards.productId]}
      </H3>

      {vipCards.cards && (
        <OffersCarousel
          offers={vipCards.cards}
          productId={vipCards.productId}
          onHandleClick={onHandleClickedCheckout}
        />
      )}
    </Fragment>
  ))

  return (
    <ContainerFullPage height="0px">
      <InterUIContainer>
        <H1 margin="24px 0 4px">{title}</H1>
        <PMedium marginBottom="24px" scale={400}>
          {subtitle}
        </PMedium>

        <CountdownTimer endDate={campaignEndDate} serverNowDate={serverDate} />
      </InterUIContainer>

      <InterUIContainer margin="0 24px" style={{ overflow: 'hidden' }}>
        {renderVipCards}
      </InterUIContainer>
    </ContainerFullPage>
  )
}
