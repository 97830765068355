import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { AlertSign, H1, PMedium, SuccessSign } from 'src/styles/commons'
import { SIZE_LG } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'

import { ISummaryItem, SummaryList } from '../../../components/SummaryList/SummaryList'

export const PaidInstallments = () => {
  const theme = useTheme()
  const history = useHistory()

  const {
    statusTitle,
    statusSubTitle,
    installmentDescription,
    amount,
    paymentDate,
    paymentMethod,
  } = useSelector(AfterSalesActions.getPaidInstallments)

  const grayscaleA100 = theme.colors.neutral.grayscale.A100

  useEffect(() => {
    BaseBridge.tacWb(
      {
        name: 'paid_installment_screen',
        module: 'consortium',
        actions: ['open_paid_installments_screen'],
        params: {
          statusTitle,
          statusSubTitle: JSON.stringify(statusSubTitle),
          installmentDescription,
          amount,
          paymentDate,
          paymentMethod,
        },
      },
      'PaidInstallments',
    )
  }, [])

  useBasePage({
    navbarTitle: PageTitles.RECEIPT,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
    hideBackNavigationBtn: statusTitle !== 'Pagamento confirmado',
  })

  const Icon = {
    success: <Icons.CheckCircle height={SIZE_LG} width={SIZE_LG} color={grayscaleA100} />,
    alert: <Icons.Pending height={SIZE_LG} width={SIZE_LG} color={grayscaleA100} />,
  }

  const firstList: ISummaryItem[] = [
    {
      id: 0,
      text: 'Mensalidade',
      value: installmentDescription,
    },
    {
      id: 1,
      text: 'Valor',
      value: amount,
    },
    {
      id: 2,
      text: 'Data do pagamento',
      value: paymentDate,
    },
    {
      id: 3,
      text: 'Forma de pagamento',
      value: paymentMethod,
    },
  ]

  const stickyFooter = (
    <InterUIContainer margin="24px">
      <Button variant="secondary" onClick={() => history.push(TypesRoutes.HOME)}>
        Voltar
      </Button>
    </InterUIContainer>
  )

  return (
    <InterUIContainer margin="0px" stickyFooter={stickyFooter}>
      <InterUIContainer margin="40px 24px 32px">
        {statusTitle === 'Pagamento confirmado' ? (
          <SuccessSign>{Icon.success}</SuccessSign>
        ) : (
          <AlertSign>{Icon.alert}</AlertSign>
        )}
        <H1 bold margin="16px 0px 8px">
          {statusTitle}
        </H1>
        <PMedium scale={400} marginBottom="0">
          {statusSubTitle}
        </PMedium>
      </InterUIContainer>

      <InterUISeparator height="8px" margin="0" />

      <InterUIContainer margin="32px 24px">
        <SummaryList title="Pagamento" items={firstList} margin="16px 0px 0px" />
      </InterUIContainer>
    </InterUIContainer>
  )
}
