import Attention from '@interco/icons/core/status/ic_warning_circle'
import BookOpen from '@interco/icons/core/home-objects/ic_book_open'
import Canceled from '@interco/icons/core/status/ic_close_circle'
import Card from '@interco/icons/core/finance/ic_credit_card'
import CheckCircle from '@interco/icons/core/status/ic_check_circle'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import ComputerCall from '@interco/icons/core/media-communication/ic_desktop_user'
import Consortium from '@interco/icons/core/privacy-security/ic_key'
import Credit from '@interco/icons/core/human/ic_hand_money'
import Deposit from '@interco/icons/core/files/ic_contract'
import Dollar from '@interco/icons/core/finance/ic_coin'
import Edit from '@interco/icons/core/action-navigation/ic_edit'
import Email from '@interco/icons/core/media-communication/ic_envelope'
import Handbag from '@interco/icons/core/travel-mobility/ic_briefcase'
import Help from '@interco/icons/core/status/ic_help_circle'
import Home from '@interco/icons/core/action-navigation/ic_house'
import NoTaxes from '@interco/icons/core/finance/ic_coin_off'
import NotFound from '@interco/icons/core/action-navigation/ic_search_error'
import PdfFile from '@interco/icons/core/files/ic_file_pdf'
import Pending from '@interco/icons/core/action-navigation/ic_clock'
import Phone from '@interco/icons/core/media-communication/ic_phone'
import PhoneCall from '@interco/icons/core/media-communication/ic_phone_ring'
import Profitability from '@interco/icons/core/finance/ic_rotate_money'
import Receipt from '@interco/icons/core/files/ic_document'
import Tag from '@interco/icons/core/shopping/ic_tag'
import DeliveryTime from '@interco/icons/core/action-navigation/ic_stopwatch'
import Ticket from '@interco/icons/core/shopping/ic_ticket'
import Star from '@interco/icons/core/action-navigation/ic_star'
import Wallet from '@interco/icons/core/finance/ic_wallet'
import WhatsApp from '@interco/icons/core/brands/ic_whatsapp'

export const Icons = {
  BookOpen,
  Canceled,
  Card,
  Credit,
  Deposit,
  Dollar,
  Edit,
  Help,
  Home,
  Handbag,
  Ticket,
  Tag,
  DeliveryTime,
  Profitability,
  NoTaxes,
  NotFound,
  ChevronRight,
  Consortium,
  Wallet,
  CheckCircle,
  Attention,
  PdfFile,
  Receipt,
  Phone,
  PhoneCall,
  Pending,
  ComputerCall,
  Star,
  WhatsApp,
  Email,
}
