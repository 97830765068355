import { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IFilterCard } from 'src/Interfaces/IFilterCard'
import { FilterCardEnum } from 'src/enums/FilterCard'
import { PageTitles } from 'src/enums/PageTitles'
import { PreSimulateEnum } from 'src/enums/PreSimulate'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { UrlActions } from 'src/store/ducks/aboutUrl/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { ICard } from 'src/store/ducks/hiring/types'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TabActions } from 'src/store/ducks/tab/actions'
import { H1, H3, PMedium } from 'src/styles/commons'
import { SIZE_MD, SIZE_SM } from 'src/utils/constants'
import { useTheme } from 'styled-components'
import { Icons } from 'src/utils/icons'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { Card } from 'src/components'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { InterUIContainer, InterUITag } from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'

import { Amount, Filters, Grid, NoCards } from './LettersCredit.styles'
import { AnalyticsLetterCreditClick } from './LettersCredit.tags'

export const LettersCredit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const handlerError = useLocalErrorHandler()

  const account_context = useSelector(NewRelicActions.getUserContext)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const deeplinkFilter = useSelector(PathParamsActions.getDeepLinkCardsFilter)
  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)
  const selectedTab = useSelector(TabActions.get)
  const selectedValue = useSelector(HiringActions.getSelectedValue)
  const { productId, subProductId, product, isVip } = useSelector(HiringActions.getSelectedModality)
  const { mainTitle, mainSubtitle, cards } = useSelector(HiringActions.getCards)

  const [currentFilter, setCurrentFilter] = useState(deeplinkFilter || FilterCardEnum.MIN)

  const notFound = (
    <Icons.NotFound width={SIZE_MD} height={SIZE_MD} color={theme.colors.neutral.grayscale.A300} />
  )

  const iconEdit = (
    <Icons.Edit
      width={SIZE_SM}
      height={SIZE_SM}
      color={theme.colors.primary.A500}
      onClick={() => history.push(TypesRoutes.PRE_SIMULATE)}
      data-testid="test_buttonEdit"
    />
  )

  useEffect(() => {
    dispatch(UrlActions.knowMore())

    BaseBridge.tacWb(
      {
        name: 'screen_letters_credit',
        module: 'consortium',
        actions: [
          'open_letters_credit_screen_via_btn_simulate',
          'open_letters_credit_screen_via_btn_see_all_consortiums',
        ],
        params: {
          segment,
          accountContext: account_context,
          mainTitle,
          mainSubtitle,
          cards: JSON.stringify(cards),
        },
      },
      'LettersCredit',
    )
  }, [])

  const handleBackButton = () => {
    if (accessViaDeepLink && !subProductId) {
      dispatch(PathParamsActions.setAccessedViaDeepLink(false))
      history.replace(TypesRoutes.START)
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: handleBackButton,
  })

  const handleClickHighlights = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        isVip: isVip || false,
        highlights: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const handleClickLowestPrice = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        isVip: isVip || false,
        lowestPrice: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const handleClickBiggestPrice = (filter: FilterCardEnum) => {
    dispatch(
      HiringActions.getCardsRequest({
        productId,
        isVip: isVip || false,
        biggestPrice: true,
      }),
    )
    setCurrentFilter(filter)
  }

  const filters: IFilterCard[] = [
    { label: FilterCardEnum.MIN, action: handleClickLowestPrice },
    { label: FilterCardEnum.MAX, action: handleClickBiggestPrice },
    { label: FilterCardEnum.HIGHLIGHTS, action: handleClickHighlights },
  ]

  const onHandleClickedDetail = (card: ICard) => {
    AnalyticsLetterCreditClick(
      TagEventsEnum.CONSORTIUM_FLOW,
      {
        account_context,
        segment,
        action_id: card.cardTitle,
        modality: product,
        offer: `${card.offer}`,
        card_value: card.consortiumValue.toString(),
        installment_value: card.installmentValue.toString(),
      },
      handlerError,
    )

    BaseBridge.ticWb(
      {
        name: 'btn_letter_card',
        module: 'consortium',
        action: 'open_screen_checkout',
        params: {
          modality: product,
          offer: card.offer,
          cardId: card.cardId,
          productId: card.productId,
          subProductId,
          group: card.group,
          cardValue: card.consortiumValue,
          installmentValue: card.installmentValue,
          isVip: card.isVip,
        },
      },
      'LettersCredit.onHandleClickedDetail',
    )

    dispatch(
      HiringActions.getCheckoutRequest({
        history,
        pathname: TypesRoutes.CHECKOUT,
        cardId: card.cardId,
        productId: card.productId,
        subProductId,
        group: card.group as number,
        installmentValue: card.installmentValue,
        isVip: card.isVip,
      }),
    )
  }

  const renderCards =
    cards && cards.length !== 0 ? (
      <Grid>
        {cards?.map((card, index) => (
          <Card
            key={uuid()}
            cardDetails={card}
            onClickedDetail={() => onHandleClickedDetail(card)}
            data-testid={`card-${index}`}
          />
        ))}
      </Grid>
    ) : (
      <NoCards>
        {notFound}
        <H3 margin="24px 0 8px">Não encontramos opções com o valor desejado</H3>
        <PMedium scale={400} marginBottom="0">
          Busque um valor diferente e escolha a melhor opção para você.
        </PMedium>
      </NoCards>
    )

  const renderValues = (
    <Amount>
      <PMedium marginBottom="0" bold>
        {selectedTab === PreSimulateEnum.INSTALLMENT
          ? 'Parcelas a partir de '
          : 'Valor total a partir de '}
        {formatToBRL(selectedValue)}
      </PMedium>
      {!accessViaDeepLink && iconEdit}
    </Amount>
  )

  const renderFilters = (
    <>
      <Filters>
        {filters.map((filter, index) => (
          <InterUITag
            key={uuid()}
            id={filter.label}
            variant="action-check"
            type="radio"
            checked={filter.label === currentFilter}
            margin={index !== filters.length - 1 ? '0 8px 0 0' : '0'}
            onChange={() => filter.action(filter.label)}
            data-testid={`test_filter-${index}`}
          >
            {filter.label}
          </InterUITag>
        ))}
      </Filters>
    </>
  )

  return (
    <InterUIContainer margin="0px 24px 24px">
      <InterUIContainer margin="24px 0px">
        <H1 marginBottom="4px">{mainTitle}</H1>
        <PMedium marginBottom="24px" scale={400}>
          {mainSubtitle}
        </PMedium>

        {selectedValue > 0 ? renderValues : renderFilters}

        {renderCards}
      </InterUIContainer>
    </InterUIContainer>
  )
}
