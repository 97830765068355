import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { IModality } from 'src/store/ducks/hiring/types'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { ContainerFullPage, H1, PMedium } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { InterUIContainer, InterUIListDescription } from '@interco/inter-ui-react-lib'

import { Carousel } from './Carousel/Carousel'
import { ContentHelp } from './Home.styles'
import { Modalities } from './Modalities/Modalities'
import { PartnerLogo } from './PartnerLogo/PartnerLogo'
import { AnalyticsHomeAccess, AnalyticsHomeClick } from './Home.tags'

export const Home = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const handlerError = useLocalErrorHandler()

  const account_context = useSelector(NewRelicActions.getUserContext)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const homeResponse = useSelector(HiringActions.getHome)
  const myConsortia = useSelector(AfterSalesActions.getMyConsortia)

  const iconArrow = (
    <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={theme.colors.primary.A500} />
  )
  const iconConsortium = (
    <Icons.Consortium width={SIZE_MD} height={SIZE_MD} color={theme.colors.primary.A500} />
  )

  useBasePage({
    navbarTitle: PageTitles.CONSORTIA,
    backNavigationHandler: BaseBridge.requestGoBack,
  })

  useEffect(() => {
    AnalyticsHomeAccess(
      TagEventsEnum.CONSORTIUM_ACCESS,
      {
        account_context,
        segment,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
      },
      handlerError,
    )

    BaseBridge.tacWb(
      {
        name: 'home_screen',
        module: 'consortium',
        actions: ['redirect_home_page'],
        params: {
          accountContext: account_context || 'SEM_CONTEXTO_CAMPANHA',
          segment,
        },
      },
      'Home',
    )
  }, [])

  const handleSelectedModality = async (modality: IModality) => {
    AnalyticsHomeClick(
      TagEventsEnum.CONSORTIUM_FLOW,
      {
        ref_figma: '2',
        account_context,
        segment,
        action_id: modality?.product,
        modality: modality?.product,
        offer: `${modality?.offer}`,
      },
      handlerError,
    )

    BaseBridge.ticWb(
      {
        name: 'btn_card_modality',
        module: 'consortium',
        action: 'open_pre_simulate',
        params: {
          accountContext: account_context,
          offer: modality?.product,
          modality: modality?.offer,
        },
      },
      'Home.handleSelectedModality',
    )
    dispatch(HiringActions.setSelectedModality(modality))
    history.push(TypesRoutes.PRE_SIMULATE)
  }

  const handleMyConsortia = () => {
    if (myConsortia && Object.keys(myConsortia)?.length === 0) {
      dispatch(AfterSalesActions.getMyConsortiaRequest())
    }

    history.push(TypesRoutes.MY_CONSORTIA)

    BaseBridge.ticWb(
      {
        name: 'btn_my_consortia',
        module: 'consortium',
        action: 'open_my_consortia_screen',
        params: {
          accountContext: account_context,
          segment,
          utmCampaign,
          myConsortia: myConsortia ? JSON.stringify(myConsortia) : 'SEM_MEU_CONSORCIO',
        },
      },
      'Home.handleMyConsortia',
    )
  }

  const handleWhatIsConsortium = () => {
    AnalyticsHomeClick(
      TagEventsEnum.CONSORTIUM_FLOW,
      {
        ref_figma: '3',
        action_id: 'Saber mais',
        account_context,
        segment,
      },
      handlerError,
    )

    history.push(TypesRoutes.FAQ)
  }

  return (
    <>
      {homeResponse?.modalities && (
        <ContainerFullPage margin="0px" height="0px">
          <InterUIContainer margin="24px 24px 32px">
            {homeResponse?.hasActiveConsortium && (
              <InterUIListDescription
                iconLeft={iconConsortium}
                iconRight={iconArrow}
                withBorder
                margin="0px 0px 24px 0px"
                onClick={handleMyConsortia}
                role="group"
                data-testid="test_buttonMyConsortia"
              >
                <PMedium marginBottom="0px" bold>
                  Meus consórcios
                </PMedium>
              </InterUIListDescription>
            )}
            <H1 marginBottom="8px">{homeResponse?.mainTitle}</H1>
            <PMedium scale={400} marginBottom="0px">
              {homeResponse?.mainSubtitle}
            </PMedium>
          </InterUIContainer>

          {homeResponse?.banners && homeResponse?.banners?.length > 0 && (
            <InterUIContainer margin="0px 0px 40px">
              <Carousel banners={homeResponse?.banners} />
            </InterUIContainer>
          )}

          <InterUIContainer margin="0px 24px 24px">
            {homeResponse?.modalities && (
              <Modalities
                modalities={homeResponse.modalities}
                selectedItem={(modality: IModality) => handleSelectedModality(modality)}
              />
            )}
          </InterUIContainer>
          <InterUIContainer margin="24px 24px 32px">
            <ContentHelp onClick={handleWhatIsConsortium} data-testid="test_contentHelp">
              <PMedium color={theme.colors.primary.A500} bold marginBottom="0">
                O que é consórcio
              </PMedium>
              <Icons.Help color={theme.colors.primary.A500} width={16} height={16} />
            </ContentHelp>
          </InterUIContainer>
          {homeResponse.bamaqLogoUrl && (
            <InterUIContainer>
              <PartnerLogo logoUrls={homeResponse.bamaqLogoUrl} />
            </InterUIContainer>
          )}
        </ContainerFullPage>
      )}
    </>
  )
}
