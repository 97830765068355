import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Skeleton } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { formatDateWithoutYear } from 'src/utils/commons'
import { ContainerFullPage } from 'src/styles/commons'
import { TagEventsEnum } from 'src/enums/TagEvents'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { InterUIContainer, InterUISeparator } from '@interco/inter-ui-react-lib'
import { formatToBRL } from '@interco/lib-util'

import { ConsortiumData } from './ConsortiumData/ConsortiumData'
import { ReceiptStatus } from './ReceiptStatus/ReceiptStatus'
import { AnalyticsReceiptAccess } from './Receipt.tags'

export const Receipt = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handlerError = useLocalErrorHandler()

  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const { status, sectionBody } = useSelector(HiringActions.getReceipt)
  const { product, offer } = useSelector(HiringActions.getSelectedModality)
  const selectedPlan = useSelector(HiringActions.getPlan)

  const consortiumData = {
    group: sectionBody?.group,
    amount: formatToBRL(sectionBody?.amount),
    cardValue: formatToBRL(sectionBody?.cardValue),
    planInstallment: `${sectionBody?.planInstallment} meses`,
    cardInstallment: sectionBody?.cardInstallment,
    dateRequest: formatDateWithoutYear(sectionBody?.dateRequest),
    datePayment: formatDateWithoutYear(sectionBody?.datePayment),
    paymentType: sectionBody?.paymentType,
  }

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    showHomeBtn: true,
    hideFaqBtn: true,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    AnalyticsReceiptAccess(
      TagEventsEnum.CONSORTIUM_SUCCESS,
      {
        account_context,
        segment,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
        status,
        modality: product,
        offer: `${offer}`,
        plan: selectedPlan.plan,
        data: consortiumData.dateRequest,
        card_value: consortiumData.cardValue,
        installment_value: consortiumData.amount,
        payment_method: consortiumData.paymentType,
      },
      handlerError,
    )

    BaseBridge.tacWb(
      {
        name: 'screen_receipt',
        module: 'consortium',
        actions: [
          'open_receipt_screen_via_btn_hire',
          'open_receipt_screen_my_card_analysis_or_refused',
        ],
        params: {
          accountContext: account_context,
          segment,
          modalities: product,
          status: JSON.stringify(status),
          cardValue: consortiumData.cardValue,
          installmentValue: consortiumData.amount,
          paymentMethod: consortiumData.paymentType,
        },
      },
      'Receipt',
    )
  }, [])

  const handleHome = async () => {
    dispatch(PathParamsActions.setAccessedViaDeepLink(false))
    history.push(TypesRoutes.START)

    BaseBridge.ticWb(
      {
        name: 'btn_go_home',
        module: 'consortium',
        action: 'redirect_home_page',
        params: {
          status: JSON.stringify(status),
          sectionBody: JSON.stringify(sectionBody),
        },
      },
      'Receipt.handleHome',
    )
  }

  const stickyFooter = (
    <InterUIContainer margin="32px 24px">
      <Button variant="primary" margin="0 0 16px" onClick={handleHome}>
        Ir para a home
      </Button>
    </InterUIContainer>
  )
  return status && sectionBody ? (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <ReceiptStatus status={status} />
      <InterUISeparator />
      <ConsortiumData {...consortiumData} />
      <InterUISeparator />
    </InterUIContainer>
  ) : (
    <ContainerFullPage height="0px" overflowHidden>
      <Skeleton.LoadReceipt />
    </ContainerFullPage>
  )
}
