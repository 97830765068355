import styled from 'styled-components'

export const ManualContainer = styled.div<{ margin: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ margin }) => margin && `margin: ${margin};`}

  > :first-child {
    margin-right: 16px;
  }
`

export const ManualTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 100%;
`
