import BaseService from 'src/config/bridge/BaseService'
import {
  ICardsResponse,
  ICheckout,
  ICheckoutRequest,
  IHome,
  ILettersCreditRequest,
  IModality,
  IProposalResponse,
  IProposalServiceRequest,
  IReceipt,
  IVipCardsResponse,
} from 'src/store/ducks/hiring/types'

import { MockService } from './mockService'

export default class HiringService {
  static getHome() {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    return BaseService.doExecute<IHome>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/home-page`,
      headers,
    })
  }

  static getSubProduct(subProductFilter: string) {
    const headers = {
      subProductFilter,
    }

    return BaseService.doExecute<IModality>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/sub-product`,
      headers,
    })
  }

  static getCards(payload: ILettersCreditRequest) {
    const {
      productId,
      subProductId,
      installmentAmount,
      totalAmount,
      highlights,
      biggestPrice,
      lowestPrice,
      isVip,
    } = payload

    const headers = { ...MockService.matchHeadersByUserAccount }

    const queryParams = [
      productId && `productId=${productId}`,
      subProductId && `subProductId=${subProductId}`,
      installmentAmount && `installmentAmount=${installmentAmount}`,
      totalAmount && `totalAmount=${totalAmount}`,
      highlights && `highlights=${highlights}`,
      biggestPrice && `biggestPrice=${biggestPrice}`,
      lowestPrice && `lowestPrice=${lowestPrice}`,
      isVip && `isVip=${isVip}`,
    ]
      .filter(Boolean)
      .join('&')

    const endpoint = `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/cards?${queryParams}`

    return BaseService.doExecute<ICardsResponse>({
      method: 'GET',
      endpoint,
      headers,
    })
  }

  static getVipCards() {
    const endpoint = `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/cards-vip`
    const headers = { ...MockService.matchHeadersByUserAccount }

    return BaseService.doExecute<IVipCardsResponse>({
      method: 'GET',
      endpoint,
      headers,
    })
  }

  static getCheckout({
    cardId,
    productId,
    subProductId,
    group,
    installmentValue,
    isVip,
  }: ICheckoutRequest) {
    const headers = {
      ...MockService.matchHeadersByUserAccount,
    }

    const queryParams = [
      cardId && `cardId=${cardId}`,
      productId && `productId=${productId}`,
      subProductId && `subProductId=${subProductId}`,
      group && `group=${group}`,
      installmentValue && `installmentValue=${installmentValue}`,
      isVip && `isVip=${isVip}`,
    ]
      .filter(Boolean)
      .join('&')

    const endpoint = `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/checkout?${queryParams}`

    return BaseService.doExecute<ICheckout>({
      method: 'GET',
      endpoint,
      headers,
    })
  }

  static getProposal({
    bankAccount,
    insurance,
    planId,
    consortiumId,
    installmentValue,
    typeSaleGroup,
  }: IProposalServiceRequest) {
    const headers = {
      bankAccount,
      insurance,
      planId,
      consortiumId,
      installmentValue,
      typeSaleGroup,
    }

    return BaseService.doExecute<IProposalResponse>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/proposal`,
      headers,
    })
  }

  static getReceipt(bankAccount: string, proposalUuid: string) {
    const headers = {
      bankAccount,
      proposalUuid,
    }

    return BaseService.doExecute<IReceipt>({
      method: 'GET',
      endpoint: `${process.env.REACT_APP_API_CONS_BFF_V1}/pages/payment-receipt`,
      headers,
    })
  }
}
