import { ReactElement } from 'react'

import { IQuickAcessBar } from 'src/Interfaces/afterSales'

export interface IQuickAccessBar {
  id: number
  icon: ReactElement
  description: string
  qtdExtracts?: number
  label?: string | ReactElement
}

export interface IQuickAccessProps {
  quickAcessBar: IQuickAcessBar
}

export const BTN_PROPOSAL_MY_CONSORTIA_DETAIL = 'btn_proposal_my_consortia_detail'
export const OPEN_PROPOSAL_SCREEN = 'open_proposal_screen'
export const BTN_EXTRACT_MY_CONSORTIA_DETAIL = 'btn_extract_my_consortia_detail'
export const OPEN_EXTRACT_SCREEN = 'open_extract_screen'
