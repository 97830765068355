import { IconType } from 'src/common/icons/types'

export const productEnum: { [key: number]: string } = {
  1: 'Automóvel',
  2: 'Motos',
  3: 'Imóvel',
  4: 'Veiculo pesado',
  5: 'Veiculo de luxo',
  6: 'Eletrônicos',
  7: 'Reforma',
  8: 'Serviço estético',
  9: 'Viagens',
  10: 'Painéis Solares',
}

export const productIconMap: { [key: number]: IconType } = {
  1: IconType.CAR,
  2: IconType.MOTORCYCLE,
  3: IconType.HOME,
  4: IconType.TRUCK,
  5: IconType.CAR,
  6: IconType.COMPUTER,
  7: IconType.SERVICES,
  8: IconType.SERVICES,
  9: IconType.HANDBAG,
  10: IconType.SERVICES,
}
