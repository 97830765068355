import { SkeletonBox, SkeletonContent } from 'src/components/Skeleton/Skeleton.styles'

import { InterUISkeleton } from '@interco/inter-ui-react-lib'

export const LoadCards = () => (
  <SkeletonBox padding="16px 8px 8px" style={{ marginBottom: '0px' }} data-testid="loading-cards">
    <SkeletonContent>
      <InterUISkeleton height="16px" width="50%" marginBottom="4px" />
      <InterUISkeleton height="16px" width="80%" marginBottom="12px" />
      <InterUISkeleton height="73px" width="100%" marginBottom="8px" />
      <InterUISkeleton height="12px" width="40%" marginBottom="0px" />
    </SkeletonContent>
  </SkeletonBox>
)
