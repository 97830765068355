import styled from 'styled-components'

import { ISeparatorProps } from './Separator.types'

export const Container = styled.div<{
  $borderWidth: string
  type: ISeparatorProps['type']
  color: string
  $margin?: string
}>`
  width: 100%;
  ${(props) => props.$margin && `margin: ${props.$margin};`}
  ${(props) =>
    props.type === 'dashed'
      ? `
    background-image: linear-gradient(to right,  ${props.color} 50%, var(--neutral-theme) 0%);
    background-position: bottom;
    background-size: 12px ${props.$borderWidth};
    background-repeat: repeat-x;
    background-color: var(--neutral-theme);
    height: ${props.$borderWidth};
  `
      : `
      border-top: ${props.$borderWidth} ${props.type} ${props.color}
      `}
`
