import { IConsortiumProgress } from 'src/Interfaces/afterSales'
import { SummaryList } from 'src/components'
import { ISummaryItem } from 'src/components/SummaryList/SummaryList'
import { Div, H1, PLarge, PMedium, PSmall, PXSmall, SpaceBetween } from 'src/styles/commons'
import { numberFormat } from 'src/utils/commons'
import { useTheme } from 'styled-components'

import { InterUIBox, InterUIProgressBar, InterUISeparator } from '@interco/inter-ui-react-lib'

interface IConsortiumProgressProps {
  consortiumProgress: IConsortiumProgress
}

export const ConsortiumProgress = ({ consortiumProgress }: IConsortiumProgressProps) => {
  const theme = useTheme()

  const {
    totalInstallments,
    paidInstallments,
    lateInstallments,
    remainingValue,
    paidValue,
    progressBarValue,
    progressRemainingPercent,
  } = consortiumProgress

  const summaryList: ISummaryItem[] = [
    {
      id: 0,
      text: 'Parcelas totais',
      value: totalInstallments?.toString(),
    },
    {
      id: 1,
      text: 'Parcelas pagas',
      value: paidInstallments?.toString(),
      color: theme.colors.feedback.success.A500,
    },
    {
      id: 2,
      text: 'Parcelas atrasadas',
      value: lateInstallments?.toString(),
      color: theme.colors.feedback.error.A500,
    },
  ]

  return (
    <InterUIBox direction="column" align="initial" padding="16px 16px 12px">
      <SpaceBetween margin="0px 0px 16px">
        <Div>
          <PMedium scale={400} marginBottom="4px">
            Valor restante
          </PMedium>
          <H1>{numberFormat(remainingValue)}</H1>
        </Div>
        <Div>
          <PMedium scale={400} marginBottom="4px" textAlign="end">
            Valor pago
          </PMedium>
          <PLarge color={theme.colors.feedback.success.A500} fontWeight="600">
            {numberFormat(paidValue)}
          </PLarge>
        </Div>
      </SpaceBetween>
      <SpaceBetween>
        <PXSmall scale={400}>Progresso do consórcio</PXSmall>
        <PXSmall scale={400}>{progressBarValue}</PXSmall>
      </SpaceBetween>
      <Div>
        <InterUIProgressBar
          // TODO: Ver a viabilidade da porcentagem vir numa variável específica
          progress={`${progressRemainingPercent.split(' de ')[0]}%`}
          margin="0px 0px 8px"
          height="8px"
          barColor={theme.colors.neutral.grayscale.A100}
          progressColor={theme.colors.feedback.success.A500}
          rounded
        />
        <PSmall textAlign="end" scale={400} bold marginBottom="0px">
          {progressRemainingPercent}
        </PSmall>
      </Div>
      <InterUISeparator
        margin="16px 0px"
        height="2px"
        style={{ color: theme.colors.neutral.grayscale.A200 }}
      />
      <PMedium bold>Status das parcelas</PMedium>
      <SummaryList items={summaryList} />
    </InterUIBox>
  )
}
