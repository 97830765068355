import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 16px;

  .slider {
    .slick-slider {
      position: static;

      .slick-list {
        margin-right: 35%;
        overflow: visible;
      }

      .slick-slide {
        display: block;
        padding: 0 4px;
      }

      .slick-dots {
        position: relative;
        bottom: auto;
        margin-top: 16px;

        li {
          transition: all 0.3s linear;
          transform-origin: center;
          background-color: ${(props) => props.theme.colors.primary.A200};

          button {
            margin-top: 0;
          }
        }
      }
    }
  }
`
