import { DeepLinkHandler } from 'src/routes/deepLinkHandler'
import { IBanner } from 'src/store/ducks/hiring/types'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { Carousel as Slider } from '@interco/inter-ui/components/Carousel'

import { BannerImage, ContentSlider } from './Carousel.styles'
import { AnalyticsCarouselClick } from './Carousel.tags'

interface ICarouselProps {
  banners: IBanner[]
}

export const Carousel = ({ banners }: ICarouselProps) => {
  const handleError = useLocalErrorHandler()
  const deepLinkHandler = DeepLinkHandler()

  const handleClickBanner = (banner: IBanner) => {
    deepLinkHandler(banner?.route)

    AnalyticsCarouselClick(
      TagEventsEnum.CONSORTIUM_BANNER,
      {
        action_id: banner?.title ?? 'SEM_BANNER_TITLE',
        content_id: banner?.title ?? 'SEM_BANNER_TITLE',
        position: `${banner?.order ?? 'SEM_BANNER_ORDER'}`,
        link: banner?.route ?? 'SEM_BANNER_ROUTE',
      },
      handleError,
    )
  }

  return (
    <ContentSlider>
      <Slider
        dots
        arrows={false}
        infinite={false}
        speed={500}
        slidesToShow={1}
        dotsType="medium"
        verticalMode={false}
        centerMode
        centerPadding="24px"
        className="slider"
      >
        {banners?.map((banner) => (
          <BannerImage
            data-testid="test-banner"
            key={`${banner?.title}-${banner?.order}`}
            onClick={() => handleClickBanner(banner)}
          >
            <img src={banner.url} alt={banner.title} />
          </BannerImage>
        ))}
      </Slider>
    </ContentSlider>
  )
}
