import { IconType } from 'src/common/icons/types'
import { BaseResponseBack } from 'src/config/bridge/BaseService'
import { ModalityEnum } from 'src/enums/ModalityEnum'
import { ICheckout, IPlanSelected } from 'src/store/ducks/hiring/types'

export const browserTransactionId = {
  processed: 'b62e7d02-8bf3-4888-bd99-6b0548b0435d',
}

export const formPaymentEmpty = {
  title: 'Formas de pagamento',
  subTitle: '',
  type: '',
}

export const formPaymentCredit = {
  title: 'Crédito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: 'CREDIT',
}

export const formPaymentDebit = {
  title: 'Débito Inter',
  subTitle: 'Não cobrado em ambiente de desenvolvimento.',
  type: 'DEBIT',
}

export const checkout: ICheckout = {
  category: ModalityEnum.TRAVEL,
  consortiumValue: 10000,
  group: 10,
  icon: IconType.HANDBAG,
  installmentNumber: 10,
  billingDate: '2023-09-21',
  reserveFundFee: 9,
  administrationFee: 13,
  isVip: true,
  productId: 1,
  plan: {
    title: 'Planos disponíveis para este valor',
    plans: [
      {
        cardId: 1,
        salesType: 1,
        salesPlanId: 1,
        title: 'Plano Tradicional',
        installments: '60 meses',
        amount: 'Valor das parcelas: R$ 830,00',
        offer: false,
        totalAmount: 830.0,
        select: true,
      },
      {
        cardId: 2,
        salesType: 2,
        salesPlanId: 2,
        title: 'Parcela reduzida',
        installments: '60 meses',
        firstInstallments: 'Primeiras 6 parcelas: R$ 8,90',
        amount: 'Demais parcelas: R$ 900,00',
        offer: false,
        totalAmount: 900.0,
        select: false,
        tagText: 'Primeira parcela reduzida',
      },
      {
        cardId: 3,
        salesType: 3,
        salesPlanId: 3,
        title: 'Plano conforto',
        subtitle:
          'Pague parcelas reduzidas até ser sorteado. O saldo restante é dividido entre as demais parcelas.',
        installments: '60 meses',
        amount: 'Valor das parcelas R$ 390,00',
        offer: true,
        totalAmount: 390.0,
        select: false,
      },
    ],
  },
  insurance: {
    title: 'Seguro Consórcio',
    subtitle:
      'Em caso de morte ou invalidez, garantimos o pagamento das prestações ou quitação do saldo devedor do seu Consórcio.',
    amount: 13.5,
    select: false,
  },
  proposalUuid: 'b62e7d02-8bf3-4888-bd99-6b0548b0435d',
  bottomSheet: [
    {
      title: 'Parcela reduzida',
      description:
        'Com esse plano, você tem redução de até 60% do valor das 6 primeiras parcelas. A partir da sétima cobrança, o valor é reajustado.',
    },
  ],
}

export const planSelected: IPlanSelected = {
  salesPlanId: 1,
  salesType: 1,
  plan: 'Plano Tradicional',
  totalAmount: 830.0,
  offer: false,
}

export const checkoutResponseMock: BaseResponseBack<ICheckout> = {
  status: 200,
  dateTime: '2023-07-10T15:53:54.895819-03:00',
  object: checkout,
}
