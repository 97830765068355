import React from 'react'

import { FallbackProps } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { ErrorPage } from './ErrorPage/ErrorPage'

export const FallbackError: React.FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const handlerError = useLocalErrorHandler()

  const errorDetails = useSelector(ErrorActions.getErrorDetails)

  const onGoBackApp = async () => {
    try {
      await BaseBridge.requestGoBack()
    } catch (error) {
      handlerError(error, 'FallbackError.onGoBackHome.BaseBridge.requestGoBack')
    }
  }

  const page = (
    <ErrorPage
      title={errorDetails.title}
      subTitle={errorDetails.subTitle}
      goHome={onGoBackApp}
      done={resetErrorBoundary}
    />
  )

  return <>{page}</>
}
