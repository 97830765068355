export enum PathParamsTypes {
  GET_PREFIX_PATH_PARAMS = `@pathparams/GET_PREFIX_PARAMS`,
  SET_PREFIX_PATH_PARAMS = `@pathparams/SET_PREFIX_PARAMS`,
  GET_UTM_CAMPAIGN_PARAMS = `@pathparams/GET_UTM_CAMPAIGN_PARAMS`,
  SET_UTM_CAMPAIGN_PARAMS = `@pathparams/SET_UTM_CAMPAIGN_PARAMS`,
  GET_DEEPLINK_CARDS_FILTER = `@pathparams/GET_DEEPLINK_CARDS_FILTER`,
  SET_DEEPLINK_CARDS_FILTER = `@pathparams/SET_DEEPLINK_CARDS_FILTER`,
  GET_ACCESSED_VIA_DEEPLINK = `@pathparams/GET_ACCESSED_VIA_DEEPLINK`,
  SET_ACCESSED_VIA_DEEPLINK = `@pathparams/SET_ACCESSED_VIA_DEEPLINK`,
  GET_EXTRACT_PATH_PARAMS = `@pathparams/GET_EXTRACT_PATH_PARAMS`,
  SET_EXTRACT_PATH_PARAMS = `@pathparams/SET_EXTRACT_PATH_PARAMS`,
}

export interface PathParamsState {
  readonly prefixPathParams: string
  readonly utmCampaignParams: string
  readonly deepLinkCardsFilter: string
  readonly accessedViaDeepLink: boolean
  readonly extractPathParams: { consortiumId: string; bankAccount: string }
}
