import { Extract, LateInstallments, MyConsortia, MyConsortiaDetail } from 'src/pages/afterSales'
import { Checkout, Receipt, LettersCredit, ExclusiveOffers, PreSimulate } from 'src/pages/hiring'
import { Home, Start } from 'src/pages/home'
import { Faq, KnowMore, PaidInstallments, Proposal } from 'src/pages/shared'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  // START
  { route: Routes.START, component: Start },

  // HOME
  { route: Routes.HOME, component: Home },

  // HIRING
  { route: Routes.PRE_SIMULATE, component: PreSimulate },
  { route: Routes.LETTERS_CREDIT, component: LettersCredit },
  { route: Routes.EXCLUSIVE_OFFERS, component: ExclusiveOffers },
  { route: Routes.CHECKOUT, component: Checkout },
  { route: Routes.RECEIPT, component: Receipt },

  // AFTER SALES
  { route: Routes.MY_CONSORTIA, component: MyConsortia },
  { route: Routes.MY_CONSORTIA_DETAIL, component: MyConsortiaDetail },
  { route: Routes.EXTRACT, component: Extract },
  { route: Routes.LATE_INSTALLMENTS, component: LateInstallments },

  // PROPOSAL
  { route: Routes.PROPOSAL, component: Proposal },

  // RECEIPT
  { route: Routes.PAID_INSTALLMENTS, component: PaidInstallments },

  // ABOUT
  { route: Routes.FAQ, component: Faq },
  { route: Routes.KNOW_MORE, component: KnowMore },
]

export * from './types'
