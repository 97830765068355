import React, { useCallback } from 'react'

import { Flex } from '@interco/inter-ui/components/Flex'
import { formatToBRL } from '@interco/lib-util'
import { Text } from '@interco/inter-ui/components/Text'

import { ContentSlider, InputRange } from './SimulationSlider.styles'

interface SimulationSliderProps {
  min: number
  max: number
  value: number
  handleSliderChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SimulationSlider = ({ min, max, value, handleSliderChange }: SimulationSliderProps) => {
  const calcBackgroundPercent = useCallback(
    (): number => ((value - min) * 100) / (max - min),
    [max, min, value],
  )

  const backgroundPercent = calcBackgroundPercent()

  return (
    <ContentSlider>
      <InputRange
        data-testid="input-inter-ui-slider"
        type="range"
        min={min}
        max={max}
        step={0.01}
        value={value}
        onChange={handleSliderChange}
        style={{
          background: `linear-gradient(to right, var(--input-range-track-filled-color) ${backgroundPercent}%, var(--input-range-track-color) ${backgroundPercent}%)`,
        }}
      />

      <Flex justifyContent="space-between" direction="row">
        <Text variant="caption-1">{formatToBRL(min)}</Text>
        <Text variant="caption-1">{formatToBRL(max)}</Text>
      </Flex>
    </ContentSlider>
  )
}

export default SimulationSlider
