import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Babi } from 'src/components'
import { PageTitles } from 'src/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { AfterSalesActions } from 'src/store/ducks/afterSales/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import BaseBridge from 'src/config/bridge/BaseBridge'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ConsortiumProgress } from './ConsortiumProgress/ConsortiumProgress'
import { Information } from './Information/Information'
import { QuickAccessBar } from './QuickAccessBar/QuickAccessBar'
import { Quota } from './Quota/Quota'

export const MyConsortiaDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { consortiumProgress, quota, quickAcessBar, contact, paymentMethod, cancellation } =
    useSelector(AfterSalesActions.getConsortiumDetails)
  const accessViaDeepLink = useSelector(PathParamsActions.getAccessedViaDeepLink)

  const handleBackButton = () => {
    if (accessViaDeepLink) {
      dispatch(AfterSalesActions.getMyConsortiaRequest())
      history.push(TypesRoutes.MY_CONSORTIA)
      return
    }

    history.goBack()
  }

  useBasePage({
    navbarTitle: PageTitles.MY_CONSORTIA,
    backNavigationHandler: handleBackButton,
    hideFaqBtn: true,
  })

  useEffect(() => {
    BaseBridge.tacWb(
      {
        name: 'my_card_others_status',
        module: 'consortium',
        actions: ['open_my_consortia_detail_screen'],
        params: {
          consortiumProgress: JSON.stringify(consortiumProgress),
          quota: JSON.stringify(quota),
          quickAcessBar: JSON.stringify(quickAcessBar),
          contact: JSON.stringify(contact),
          paymentMethod: JSON.stringify(paymentMethod),
          cancellation: JSON.stringify(cancellation),
        },
      },
      'MyConsortiaDetail',
    )
  }, [])

  const stickyFooter = <Babi margin="0px" />

  return (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <ConsortiumProgress consortiumProgress={consortiumProgress} />
      <Quota quotaInfo={quota} />
      <QuickAccessBar quickAcessBar={quickAcessBar} />
      <Information info={contact} paymentMethod={paymentMethod} cancelation={cancellation} />
    </InterUIContainer>
  )
}
