import { Tag as TagUI } from '@interco/inter-ui/components/Tag'

import { CustomInterUITag } from './Tag.styles'

interface TagProps {
  label: string
}

export const Tag = ({ label }: TagProps) => (
  <CustomInterUITag data-testid="tag-txt">
    <TagUI size="small">{label}</TagUI>
  </CustomInterUITag>
)
