import { Fragment, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ICheckoutBS } from 'src/Interfaces/ICheckout'
import { getIcon } from 'src/common/icons/icons'
import { PaymentMethod, SummaryList, Tag } from 'src/components'
import { ISummaryItem } from 'src/components/SummaryList/SummaryList'
import { CheckoutBaseService } from 'src/config/bridge/CheckoutBaseService'
import { CheckoutEnum, LabelsBSEnum } from 'src/enums/Checkout'
import { PageTitles } from 'src/enums/PageTitles'
import { SummaryListVariantsEnum } from 'src/enums/SummaryListVariants'
import { useBasePage } from 'src/hooks/useBasePage'
import { formPaymentEmpty } from 'src/mocks/Checkout'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { CheckoutActions } from 'src/store/ducks/checkout/actions'
import { ErrorActions } from 'src/store/ducks/error/actions'
import { ErrorDetails } from 'src/store/ducks/error/types'
import { HiringActions } from 'src/store/ducks/hiring/actions'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { BtnLink, H1, H2, H3, InfoSign, PLarge, PMedium, PSmall, PXSmall } from 'src/styles/commons'
import { formatDayMonthYear } from 'src/utils/commons'
import { useTheme } from 'styled-components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { Manual } from 'src/components/Manual/Manual'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import {
  InterUIBottomSheet,
  InterUIBox,
  InterUIContainer,
  InterUIRadio,
  InterUISeparator,
  InterUISwitch,
} from '@interco/inter-ui-react-lib'
import { formatToBRL, uuid } from '@interco/lib-util'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'

import { CheckoutBS } from './CheckoutBS/CheckoutBS'
import { CheckoutContainer, FooterContainer } from './Checkout.styles'
import { AnalyticsCheckoutAccess, AnalyticsCheckoutClick } from './Checkout.tags'

export const Checkout = () => {
  const history = useHistory()
  const theme = useTheme()
  const dispatch = useDispatch()
  const handlerError = useLocalErrorHandler()

  const selectedPlan = useSelector(HiringActions.getPlan)
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)
  const formOfPayment = useSelector(CheckoutActions.getFormOfPayment)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)
  const checkedConsortiumInsurance = useSelector(HiringActions.getCheckedConsortiumInsurance)
  const { plan, insurance, bottomSheet, ...checkout } = useSelector(HiringActions.getCheckout)

  const [checkoutBS, setCheckoutBS] = useState(false)
  const [aboutPLansBS, setAboutPLansBS] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [choseLabel, setChoseLabel] = useState<ICheckoutBS>(LabelsBSEnum.RESERVE)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalWithInsurance, setTotalWithInsurance] = useState(0)

  useBasePage({
    navbarTitle: PageTitles.CONSORTIUM,
    backNavigationHandler: history.goBack,
    hideFaqBtn: true,
  })

  useEffect(() => {
    AnalyticsCheckoutAccess(
      TagEventsEnum.CONSORTIUM_RESUME,
      {
        segment,
        account_context,
        utm_campaign: utmCampaign || 'SEM_CAMPANHA',
        modality: checkout.category,
        card_value: `${checkout.consortiumValue}`,
        installment_value: `${selectedPlan.totalAmount}`,
        vip: selectedPlan.salesType === 3 ? 'Oferta exclusiva' : 'Oferta padrão',
      },
      handlerError,
    )

    BaseBridge.tacWb(
      {
        name: 'screen_checkout_card',
        module: 'consortium',
        actions: ['open_screen_checkout'],
        params: {
          modality: checkout.category,
          cardValue: checkout.consortiumValue,
          installmentsValue: selectedPlan.totalAmount,
        },
      },
      'Checkout',
    )
  }, [])

  useEffect(() => {
    if (insurance) {
      checkedConsortiumInsurance
        ? setTotalAmount(selectedPlan.totalAmount + insurance.amount)
        : setTotalAmount(selectedPlan.totalAmount)

      setTotalWithInsurance(selectedPlan.totalAmount + insurance.amount)
    } else {
      setTotalAmount(selectedPlan.totalAmount)
    }
  }, [])

  const summaryList: ISummaryItem[] = [
    {
      id: 0,
      text: 'Taxa de administração',
      value: `${checkout.administrationFee}%`,
      icon: LabelsBSEnum.ADMIN,
    },
    {
      id: 1,
      text: 'Número de parcelas',
      value: `${checkout.installmentNumber}`,
    },
    {
      id: 2,
      text: 'Data de cobrança',
      value: `${checkout.billingDate}`,
    },
    {
      id: 3,
      text: 'Taxa de fundo de reserva',
      value: `${checkout.reserveFundFee}%`,
      icon: LabelsBSEnum.RESERVE,
    },
    {
      id: 4,
      text: 'Grupo',
      value: `${checkout.group}`,
      icon: LabelsBSEnum.GROUP,
    },
  ]

  const resetFormOfPayment = () => {
    dispatch(
      CheckoutActions.setFormOfPayment({
        title: formPaymentEmpty.title,
        subTitle: formPaymentEmpty.subTitle,
        type: formPaymentEmpty.type,
      }),
    )
  }

  const handleSelectedPlan = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormOfPayment()

    const { value } = event.target

    const findPlan = plan.plans.find((item) => item.salesPlanId.toString() === value)

    if (findPlan) {
      dispatch(
        HiringActions.setPlan({
          salesPlanId: findPlan.salesPlanId,
          salesType: findPlan.salesType,
          plan: findPlan.title,
          totalAmount: findPlan.totalAmount,
          offer: findPlan.offer,
        }),
      )

      if (insurance) {
        checkedConsortiumInsurance
          ? setTotalAmount(findPlan.totalAmount + insurance.amount)
          : setTotalAmount(findPlan.totalAmount)

        setTotalWithInsurance(findPlan.totalAmount + insurance.amount)
      } else {
        setTotalAmount(findPlan.totalAmount)
      }
    }
  }

  const handleClickMembershipProposal = () => {
    dispatch(
      HiringActions.getProposalRequest({
        history,
        pathname: TypesRoutes.PROPOSAL,
        consortiumId: `${checkout.proposalUuid}`,
        installmentValue: totalAmount.toFixed(2),
        insurance: `${checkedConsortiumInsurance}`,
        planId: `${selectedPlan.salesPlanId}`,
        typeSaleGroup: `${selectedPlan.salesType}`,
      }),
    )
  }

  const handleOpenBS = (item: ICheckoutBS) => {
    setChoseLabel(item)
    setCheckoutBS(true)
  }

  const handleClickContract = async () => {
    if (BaseBridge.isBrowser()) {
      dispatch(
        HiringActions.getReceiptRequest({
          proposalUuid: checkout.proposalUuid,
        }),
      )
      history.push(TypesRoutes.RECEIPT)
    } else {
      try {
        AnalyticsCheckoutClick(
          TagEventsEnum.CONSORTIUM_FLOW,
          {
            account_context,
            segment,
            utm_campaign: utmCampaign || 'SEM_CAMPANHA',
            modality: checkout.category,
            offer: `${selectedPlan.offer}`,
            card_value: `${checkout.consortiumValue}`,
            installment_value: `${selectedPlan.totalAmount}`,
            plan: selectedPlan.plan,
            payment_method: formOfPayment.type,
            seguro: `${checkedConsortiumInsurance}`,
            insurance_value: `${insurance?.amount}`,
            vip: selectedPlan.salesType === 3 ? 'Oferta exclusiva' : 'Oferta padrão',
          },
          handlerError,
        )

        BaseBridge.ticWb(
          {
            name: 'btn_contract_letter',
            module: 'consortium',
            action: 'open_receipt_screen_via_btn_hire',
            params: {
              accountContext: account_context,
              segment,
              modalities: checkout.category,
              offer: selectedPlan.offer,
              cardValue: checkout.consortiumValue,
              installmentValue: selectedPlan.totalAmount,
              plan: selectedPlan.plan,
              paymentMethod: formOfPayment.type,
              insurance: checkedConsortiumInsurance,
              insuranceValue: insurance?.amount,
            },
          },
          'Checkout.handleClickContract',
        )

        const result = await CheckoutBaseService.contractWallet(`${totalAmount}`)

        resetFormOfPayment()

        if (result.status === 'SUCCESS') {
          dispatch(
            HiringActions.getReceiptRequest({
              proposalUuid: checkout.proposalUuid,
            }),
          )
          history.push(TypesRoutes.RECEIPT)
        }
      } catch (error: unknown) {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: false,
          route: TypesRoutes.START,
        }

        handlerError(error, 'Checkout.handleClickContract.CheckoutBaseService.contractWallet')
        dispatch(ErrorActions.show(errorDetails))
      }
    }
  }

  const renderSelectPlan = (
    <>
      {plan && (
        <PLarge margin="24px 0 16px" bold>
          {plan.title}
        </PLarge>
      )}

      {plan?.plans?.map((item) => (
        <InterUIRadio
          key={uuid()}
          id={`plan-${uuid()}`}
          data-testid={`plan-${item.salesPlanId}`}
          variant="choose-item"
          radioPosition="right"
          margin="0 0 16px"
          name={item.title}
          value={item.salesPlanId}
          checked={selectedPlan.salesPlanId === item.salesPlanId}
          onChange={handleSelectedPlan}
        >
          {item?.tagText && <Tag label={item?.tagText} />}
          <PMedium bold>{item.title}</PMedium>
          {item.subtitle && <PSmall scale={400}>{item.subtitle}</PSmall>}
          <PXSmall marginBottom="4px">
            Em até <strong>{item.installments} meses</strong>
          </PXSmall>
          {item.firstInstallments && (
            <PMedium scale={400} marginBottom="4px">
              {item.firstInstallments}
            </PMedium>
          )}
          <PMedium scale={400} marginBottom="0">
            {item.amount}
          </PMedium>
        </InterUIRadio>
      ))}
    </>
  )

  const toggleInsurance = (event: React.ChangeEvent<HTMLInputElement>) => {
    resetFormOfPayment()

    const { checked } = event.target
    dispatch(HiringActions.setCheckedConsortiumInsurance(checked))

    !checkedConsortiumInsurance && insurance !== undefined
      ? setTotalAmount(selectedPlan.totalAmount + insurance.amount)
      : setTotalAmount(selectedPlan.totalAmount)
  }

  const renderInsurance = (
    <>
      {insurance && (
        <InterUIBox direction="column" margin="0 0 24px" actived={checkedConsortiumInsurance}>
          <InterUISwitch
            data-testid="test-switch"
            margin="0 0 10px"
            value="accept-insurance"
            checked={checkedConsortiumInsurance}
            onChange={toggleInsurance}
            style={{ width: '100%' }}
          >
            <PMedium marginBottom="4px" bold>
              {insurance.title}
            </PMedium>
            <PSmall marginBottom="4px" scale={400} bold>
              {`${formatToBRL(insurance.amount)} por mês`}
            </PSmall>

            <PXSmall marginBottom="0" scale={400}>
              Sua parcela com seguro {formatToBRL(totalWithInsurance)}
            </PXSmall>
          </InterUISwitch>

          <PSmall marginBottom="0" scale={400}>
            {insurance.subtitle}
          </PSmall>
        </InterUIBox>
      )}
    </>
  )

  const renderBSPlans = (
    <>
      {bottomSheet && (
        <InterUIBottomSheet
          data-testid="about-bottom-sheet"
          toggle={aboutPLansBS}
          onHide={() => setAboutPLansBS(false)}
        >
          {bottomSheet.map((item, index) => (
            <Fragment key={uuid()}>
              <PLarge bold>{item.title}</PLarge>
              <PMedium scale={400} marginBottom="24px">
                {item.description}
              </PMedium>
              {index !== bottomSheet.length - 1 && (
                <InterUISeparator height="1px" margin="0 0 24px" />
              )}
            </Fragment>
          ))}

          <Button fullWidth variant="primary" onClick={() => setAboutPLansBS(false)}>
            Entendi
          </Button>
        </InterUIBottomSheet>
      )}
    </>
  )

  const stickyFooter = (
    <FooterContainer>
      <CheckoutContainer>
        <Text variant="caption-1" colorWeight={500}>
          Li e concordo com as informações descritas no documento de{' '}
          <Button onClick={handleClickMembershipProposal} variant="link" size="small">
            Proposta de adesão.
          </Button>
        </Text>

        <Checkbox
          data-testid="test-checkbox"
          checked={acceptTerms}
          onChange={() => setAcceptTerms(!acceptTerms)}
        />
      </CheckoutContainer>

      <Button
        data-testid="button-continue"
        disabled={!acceptTerms || !formOfPayment.type}
        onClick={() => handleClickContract()}
      >
        Contratar
      </Button>
    </FooterContainer>
  )

  return (
    <>
      {Object.keys(checkout).length !== 0 && (
        <InterUIContainer margin="0px" stickyFooter={stickyFooter}>
          <InterUIContainer margin="24px 24px 32px">
            <InfoSign>
              {getIcon(checkout.icon, {
                color: theme.colors.primary.A500,
                width: 32,
                height: 32,
              })}
            </InfoSign>
            <PMedium margin="8px 0 16px" scale={400} textAlign="center">
              {checkout.category}
            </PMedium>
            <H1 marginBottom="24px" textAlign="center">
              {formatToBRL(checkout.consortiumValue)}
            </H1>
            <H3 marginBottom="16px">Detalhes</H3>

            <SummaryList
              items={summaryList}
              variant={SummaryListVariantsEnum.PS}
              handleClick={(item) => handleOpenBS(item as ICheckoutBS)}
            />

            {renderSelectPlan}

            {bottomSheet && bottomSheet.length > 0 && (
              <BtnLink onClick={() => setAboutPLansBS(true)}>Entenda mais sobre os planos</BtnLink>
            )}
          </InterUIContainer>

          <InterUISeparator height="8px" />

          <InterUIContainer margin="24px 24px 32px">
            {renderInsurance}
            <H2 marginBottom="8px">Como você quer pagar?</H2>
            <PMedium marginBottom="24px" scale={400}>
              Primeira cobrança em <strong>{formatDayMonthYear(new Date())}</strong>.
            </PMedium>
            <PaymentMethod
              type={CheckoutEnum.HIRE}
              amount={totalAmount}
              proposalUuid={checkout.proposalUuid}
              salesPlanId={selectedPlan.salesPlanId}
              salesType={selectedPlan.salesType}
              inInsurance={checkedConsortiumInsurance}
            />

            <Manual screen="checkout" margin="24px 0 0" />
          </InterUIContainer>

          {renderBSPlans}

          <CheckoutBS choseLabel={choseLabel} toggleState={[checkoutBS, setCheckoutBS]} />
        </InterUIContainer>
      )}
    </>
  )
}
