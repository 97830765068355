import axios, { Method } from 'axios'
import { MockService } from 'src/services/mockService'

import InterWebviewBridge, {
  interWbHttp,
  IWbHttpData,
  IWbHttpOptions,
} from '@interco/inter-webview-bridge'

import NewRelicUtils from '../monitoring/NewRelicUtils'

export interface BaseRequestConfig {
  method: Method
  endpoint: string
  headers?: Record<string, string>
  data?: Record<string, unknown>
  options?: IWbHttpOptions
}

export interface BaseResponseBack<T> {
  status: number
  dateTime: string
  object: T
  message?: string
}

export class BaseService {
  static async callByAxios<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const { endpoint, ...data } = config

    MockService.prepareAxios()

    try {
      const response = await axios({
        url: axios.defaults.baseURL
          ? `${axios.defaults.baseURL}/${endpoint}`
          : `${process.env.REACT_APP_API_HOST}/${endpoint}`,
        // url: `${process.env.REACT_APP_API_HOST}/${endpoint}`,
        ...data,
      })

      return {
        object: response.data.object as T,
        dateTime: response.headers.date,
        status: response.status,
        message: response.data.message,
      }
    } catch (error) {
      const result = (error as unknown as { response: { data: unknown } }).response.data
      NewRelicUtils.noticeError(error as Error, {
        errorCodeRef: 'BaseService.callByAxios',
        endpoint,
        errorObject: JSON.stringify(error),
      })

      const errorObj = {
        status: (result as { status: number })?.status || 500,
        message: (result as { message: string })?.message || error,
        dateTime: (result as { dateTime: string })?.dateTime,
      }

      return Promise.reject(errorObj)
    }
  }

  static async callByBridge<T>(config: BaseRequestConfig) {
    const { endpoint, headers, data, options } = config

    try {
      const method = config.method.toLowerCase()

      let bridgeResponse = {} as IWbHttpData

      switch (method) {
        case 'post':
        case 'put':
          bridgeResponse = await interWbHttp[method](endpoint, data, headers, options)
          break
        case 'get':
        case 'delete':
          bridgeResponse = await interWbHttp[method](endpoint, headers)
          break
        default:
          break
      }

      let parsedData = bridgeResponse.response as unknown as BaseResponseBack<T>

      if (typeof parsedData === 'string') {
        parsedData = JSON.parse(parsedData)
      }

      return {
        object: parsedData === null ? ({} as T) : (parsedData.object as T),
        dateTime: bridgeResponse.headers.date,
        status: bridgeResponse.httpStatus,
        message: parsedData === null ? undefined : parsedData.message,
      }
    } catch (error) {
      let parsedError
      let responseError

      if (error && typeof error === 'string') {
        parsedError = JSON.parse(error)
      }

      if (
        (parsedError && (parsedError as { message: string })?.message === 'Api error') ||
        (parsedError && (parsedError as { httpStatus: number })?.httpStatus === 401)
      ) {
        NewRelicUtils.noticeError(error as Error, {
          errorCodeRef: 'BaseService.callByBridge',
          endpoint,
          errorObject: JSON.stringify(parsedError),
        })
      }

      responseError = (parsedError as { response: string })?.response

      if (responseError && typeof responseError === 'string') {
        responseError = JSON.parse(responseError)
      }

      const errorObj = {
        status: (responseError as { status: number })?.status || 500,
        message: (responseError as { message: string })?.message || JSON.stringify(parsedError),
        dateTime: (responseError as { dateTime: string })?.dateTime || new Date().toISOString(),
      }

      return Promise.reject(errorObj)
    }
  }

  static async doExecute<T>(config: BaseRequestConfig): Promise<BaseResponseBack<T>> {
    const iWb = InterWebviewBridge.getInstance()

    if (iWb.getEnvironment() === 'BROWSER') {
      const response = await BaseService.callByAxios<T>(config)

      return response
    }
    return BaseService.callByBridge(config)
  }
}

export default BaseService
