import { Text } from '@interco/inter-ui/components/Text'
import { darkModeEnabled } from '@interco/inter-ui'

import { CustomText, PartnerLogContainer, PartnerLogoImg } from './PartnerLogo.styles'

interface PartnerLogoProps {
  logoUrls?: {
    light?: string
    dark?: string
  }
}

export const PartnerLogo = ({ logoUrls }: PartnerLogoProps) => {
  const isDarkModeEnabled = darkModeEnabled()
  const PARTNER_LOGO_TITLE = 'Em parceria com'
  const PARTNER_LOGO_ALT = 'Logo do parceiro'

  const logoUrl = isDarkModeEnabled ? logoUrls?.dark : logoUrls?.light

  return (
    <>
      {logoUrl && (
        <PartnerLogContainer>
          <Text variant="caption-1">
            <CustomText>{PARTNER_LOGO_TITLE}</CustomText>
          </Text>
          <PartnerLogoImg src={logoUrl} alt={PARTNER_LOGO_ALT} />
        </PartnerLogContainer>
      )}
    </>
  )
}
