import { Icons } from 'src/utils/icons'
import { SIZE_MD } from 'src/utils/constants'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { useSelector } from 'react-redux'
import { NewRelicActions } from 'src/store/ducks/newRelic/actions'
import { TagEventsEnum } from 'src/enums/TagEvents'
import { useLocalErrorHandler } from 'src/hooks/useLocalErrorHandler'

import { Text } from '@interco/inter-ui/components/Text'

import { ManualContainer, ManualTextContainer } from './Manual.styles'
import { MANUAL_URL, ManualProps } from './Manual.types'
import { AnalyticsManualClick } from './Manual.tags'

export const Manual = ({
  title = 'Manual do Consórcio',
  description = 'Mais detalhes e informações importantes',
  screen,
  margin = '0',
}: ManualProps) => {
  const handlerError = useLocalErrorHandler()

  const gray500 = 'var(--gray500)'
  const orange500 = 'var(--orange500)'
  const segment = useSelector(NewRelicActions.getUserSegmentation)
  const account_context = useSelector(NewRelicActions.getUserContext)

  const handleManual = async () => {
    AnalyticsManualClick(
      TagEventsEnum.MANUAL,
      {
        screen,
        account_context,
        segment,
      },
      handlerError,
    )

    try {
      await BaseBridge.openPdf(MANUAL_URL, 'Manual do produto')
    } catch (error) {
      handlerError(error, 'Manual.handleManual')
    }
  }

  return (
    <ManualContainer data-testid="manual-btn" onClick={handleManual} margin={margin}>
      <Icons.BookOpen width={SIZE_MD} height={SIZE_MD} color={gray500} />
      <ManualTextContainer>
        <Text variant="caption-1" colorWeight={500} bold>
          {title}
        </Text>
        <Text variant="caption-1">{description}</Text>
      </ManualTextContainer>
      <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={orange500} />
    </ManualContainer>
  )
}
