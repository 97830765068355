import { AnyAction } from 'redux'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { formPaymentEmpty } from 'src/mocks/Checkout'
import HiringService from 'src/services/HiringService'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { errorHandlingSaga, redirectThroughSaga } from 'src/store/ducks/utils/providerSaga'
import { call, put, takeLatest } from 'typed-redux-saga'

import { CheckoutActions } from '../checkout/actions'
import { HiringActions } from './actions'
import {
  HiringTypes,
  ICheckoutRequest,
  IProposalServiceRequest,
  IReceipt,
  IVipCardsRequest,
} from './types'
import { PathParamsActions } from '../pathparams/actions'

function* getHome({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    BaseBridge.requestAnalytics('C_FRONT_ISCON_HOME_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar HiringService.getHome',
    })
    const homeResponse = yield* call(HiringService.getHome)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_HOME_AFTER', {
      file: 'hiring/saga',
      action: 'Response do HiringService.getHome',
      response: JSON.stringify(homeResponse.object),
    })

    yield* put(HiringActions.setHome(homeResponse.object))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getHome', undefined, true)
    BaseBridge.tacWb(
      {
        name: 'home_screen',
        module: 'consortium',
        actions: ['redirect_home_page'],
        params: {
          payload,
          error,
        },
        isError: true,
      },
      'HiringActions.getHome',
    )
  }
}

function* getSubProduct({ payload }: AnyAction) {
  try {
    const { subProductId } = payload
    const subProductResponse = yield* call(HiringService.getSubProduct, subProductId)

    const subProdModality = {
      ...subProductResponse.object,
      offer: false,
      displayOrder: 1,
    }

    yield* put(HiringActions.setSelectedModality(subProdModality))

    yield* put(LoadingActions.hide())

    if (payload) {
      redirectThroughSaga(payload)
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getSubProduct')
  }
}

function* getCards({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { installmentAmount, totalAmount } = payload

    const value = installmentAmount || totalAmount

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CARDS_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar HiringService.getCards',
      request: JSON.stringify(payload),
    })

    const cardsResponse = yield* call(HiringService.getCards, payload)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CARDS_AFTER', {
      file: 'hiring/saga',
      action: 'Response do HiringService.getCards',
      response: JSON.stringify(cardsResponse.object),
    })

    yield* put(HiringActions.setSelectedValue(0))

    if (value) {
      yield* put(HiringActions.setSelectedValue(value))
    }

    yield* put(HiringActions.setCards(cardsResponse.object))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getCards')
    BaseBridge.tacWb(
      {
        name: 'screen_letters_credit',
        module: 'consortium',
        actions: [
          'open_letters_credit_screen_via_btn_simulate',
          'open_letters_credit_screen_via_btn_see_all_consortiums',
        ],
        params: {
          payload,
          error,
        },
        isError: true,
      },
      'HiringActions.getCards',
    )
  }
}

function* getVipCards({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { callback } = payload as IVipCardsRequest

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CARDS_VIP_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar HiringService.getVipCards',
      request: JSON.stringify(payload),
    })

    const vipCardsResponse = yield* call(HiringService.getVipCards)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CARDS_VIP_AFTER', {
      file: 'hiring/saga',
      action: 'Response do HiringService.getVipCards',
      response: JSON.stringify(vipCardsResponse),
    })

    if (vipCardsResponse.object) {
      yield* put(HiringActions.setVipCards(vipCardsResponse.object))
      callback(true)
    } else {
      yield* put(PathParamsActions.setAccessedViaDeepLink(false))
      callback(false)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* put(LoadingActions.hide())
    yield* errorHandlingSaga(error as Error, 'HiringActions.getVipCards')
    BaseBridge.tacWb(
      {
        name: 'screen_exclusive_offers',
        module: 'consortium',
        actions: ['open_exclusive_offers_screen_via_deeplink'],
        params: {
          payload,
          error,
        },
        isError: true,
      },
      'HiringActions.getVipCards',
    )
  }
}

function* getCheckout({ payload, card }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { cardId, productId, subProductId, group, installmentValue, isVip } = payload

    const request: ICheckoutRequest = {
      cardId,
      productId,
      subProductId,
      group,
      installmentValue,
      isVip,
    }

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CHECKOUT_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar HiringService.getCheckout',
      request: JSON.stringify(payload),
    })
    const checkoutResponse = yield* call(HiringService.getCheckout, request)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_CHECKOUT_AFTER', {
      file: 'hiring/saga',
      action: 'Response do HiringService.getCheckout',
      response: JSON.stringify(checkoutResponse.object),
    })

    yield* put(HiringActions.setCheckout(checkoutResponse.object))

    yield* put(
      CheckoutActions.setFormOfPayment({
        title: formPaymentEmpty.title,
        subTitle: formPaymentEmpty.subTitle,
        type: formPaymentEmpty.type,
      }),
    )

    if (checkoutResponse.object.insurance?.select) {
      yield* put(HiringActions.setCheckedConsortiumInsurance(true))
    }

    if (checkoutResponse.object.plan) {
      const findPlan = checkoutResponse.object.plan.plans.find((item) => item.select === true)

      if (findPlan) {
        yield* put(
          HiringActions.setPlan({
            salesPlanId: findPlan.salesPlanId,
            salesType: findPlan.salesType,
            plan: findPlan.title,
            totalAmount: findPlan.totalAmount,
            offer: findPlan.offer,
          }),
        )
      }
    }

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getCheckout')
    BaseBridge.tacWb(
      {
        name: 'screen_checkout_card',
        module: 'consortium',
        actions: ['open_screen_checkout'],
        params: {
          isVip: card.isVip,
          productId: card.productId,
          cardId: card.cardId,
          cardTitle: card.cardTitle,
          offer: card.offer,
          consortiumValue: card.consortiumValue,
          installmentValue: card.installmentValue,
          term: card.term,
          group: card.group,
          highlightCard: card.highlightCard,
          error,
        },
        isError: true,
      },
      'HiringActions.getCheckout',
    )
  }
}

function* getProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show())

    const { consortiumId, installmentValue, insurance, planId, typeSaleGroup } = payload

    const userInfoBridge = yield* call(BaseBridge.getUserInfo)

    const request: IProposalServiceRequest = {
      bankAccount: userInfoBridge.account,
      consortiumId,
      installmentValue,
      insurance,
      planId,
      typeSaleGroup,
    }

    BaseBridge.requestAnalytics('C_FRONT_ISCON_PROPOSAL_BEFORE', {
      file: 'hiring/saga',
      action: 'Antes de chamar HiringService.getProposal',
      request: JSON.stringify(payload),
    })
    const proposalResponse = yield* call(HiringService.getProposal, request)

    BaseBridge.requestAnalytics('C_FRONT_ISCON_PROPOSAL_AFTER', {
      file: 'hiring/saga',
      action: 'Response do HiringService.getProposal',
      response: JSON.stringify(proposalResponse.object),
    })

    yield* put(HiringActions.setProposal(proposalResponse.object))

    if (payload) {
      redirectThroughSaga(payload)
    }

    yield* put(LoadingActions.hide())
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getProposal')
    BaseBridge.tacWb(
      {
        name: 'proposal_screen',
        module: 'consortium',
        actions: ['open_proposal_screen'],
        params: {
          payload,
          error,
        },
        isError: true,
      },
      'HiringActions.getProposal',
    )
  }
}

function* getReceipt({ payload }: AnyAction) {
  try {
    const userInfoBridge = yield* call(BaseBridge.getUserInfo)
    const { proposalUuid } = payload

    if (proposalUuid) {
      yield* put(HiringActions.setReceipt({} as IReceipt))

      BaseBridge.requestAnalytics('C_FRONT_ISCON_RECEIPT_BEFORE', {
        file: 'hiring/saga',
        action: 'Antes de chamar HiringService.getReceipt',
        request: JSON.stringify(payload),
      })
      const response = yield* call(HiringService.getReceipt, userInfoBridge.account, proposalUuid)

      BaseBridge.requestAnalytics('C_FRONT_ISCON_RECEIPT_AFTER', {
        file: 'hiring/saga',
        action: 'Response do HiringService.getReceipt',
        response: JSON.stringify(response.object),
      })

      yield* put(HiringActions.setReceipt(response.object))
    } else {
      const error = new Error('Undefined parameters')

      yield* errorHandlingSaga(error, 'HiringActions.getReceipt')
    }
  } catch (error) {
    yield* errorHandlingSaga(error as Error, 'HiringActions.getReceipt')
    BaseBridge.tacWb(
      {
        name: 'screen_receipt',
        module: 'consortium',
        actions: [
          'open_receipt_screen_via_btn_hire',
          'open_receipt_screen_my_card_analysis_or_refused',
        ],
        params: {
          payload,
          error,
        },
        isError: true,
      },
      'HiringActions.getReceipt',
    )
  }
}

export default function* watchHiring() {
  yield* takeLatest(HiringTypes.GET_HOME_CALL_REQUEST, getHome)
  yield* takeLatest(HiringTypes.GET_SUBPRODUCT_REQUEST, getSubProduct)
  yield* takeLatest(HiringTypes.GET_CARDS_REQUEST, getCards)
  yield* takeLatest(HiringTypes.GET_VIP_CARDS_REQUEST, getVipCards)
  yield* takeLatest(HiringTypes.GET_CHECKOUT_REQUEST, getCheckout)
  yield* takeLatest(HiringTypes.GET_PROPOSAL_REQUEST, getProposal)
  yield* takeLatest(HiringTypes.GET_RECEIPT_REQUEST, getReceipt)
}
