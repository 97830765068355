import { Reducer } from 'redux'

import { PathParamsState, PathParamsTypes } from './types'

const INITIAL_STATE: PathParamsState = {
  prefixPathParams: '',
  utmCampaignParams: '',
  deepLinkCardsFilter: '',
  accessedViaDeepLink: false,
  extractPathParams: { consortiumId: '', bankAccount: '' },
}

export const reducer: Reducer<PathParamsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PathParamsTypes.SET_PREFIX_PATH_PARAMS:
      return { ...state, prefixPathParams: action.payload }
    case PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS:
      return { ...state, utmCampaignParams: action.payload }
    case PathParamsTypes.SET_DEEPLINK_CARDS_FILTER:
      return { ...state, deepLinkCardsFilter: action.payload }
    case PathParamsTypes.SET_ACCESSED_VIA_DEEPLINK:
      return { ...state, accessedViaDeepLink: action.payload }
    case PathParamsTypes.SET_EXTRACT_PATH_PARAMS:
      return { ...state, extractPathParams: action.payload }
    default:
      return state
  }
}

export default reducer
