import { Suspense, lazy } from 'react'

import { IconParams, IconType } from './types'

const Handbag = lazy(() => import('@interco/icons/core/travel-mobility/ic_plane_departure'))
const Car = lazy(() => import('@interco/icons/core/travel-mobility/ic_car_front'))
const Motorcycle = lazy(() => import('@interco/icons/core/travel-mobility/ic_motorcycle'))
const Home = lazy(() => import('@interco/icons/core/travel-mobility/ic_building'))
const Computer = lazy(() => import('@interco/icons/core/media-communication/ic_desktop'))
const Truck = lazy(() => import('@interco/icons/core/travel-mobility/ic_truck'))
const Services = lazy(() => import('@interco/icons/core/human/ic_hand_config'))
const NotFound = lazy(() => import('@interco/icons/core/action-navigation/ic_search_error'))

export const iconParamsDefaults: IconParams = {
  color: '#FF7A00',
  width: 20,
  height: 20,
}

const fallbackParams = (params: IconParams): IconParams => {
  const { color, width, height, action } = params
  return {
    color: color || iconParamsDefaults.color,
    width: width || iconParamsDefaults.width,
    height: height || iconParamsDefaults.height,
    action,
  }
}

/**
 * Function to return the icons from enum
 * @param icon to return
 * @param params to build the icon
 */
export const getIcon = (type: IconType, params: IconParams = { ...iconParamsDefaults }) => {
  const { color, width, height, action } = fallbackParams(params)

  switch (type) {
    case IconType.HANDBAG: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Handbag
            data-testid={IconType.HANDBAG}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.CAR: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Car
            data-testid={IconType.CAR}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.MOTORCYCLE: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Motorcycle
            data-testid={IconType.MOTORCYCLE}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.HOME: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Home
            data-testid={IconType.HOME}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.TRUCK: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Truck
            data-testid={IconType.TRUCK}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.SERVICES: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Services
            data-testid={IconType.SERVICES}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    case IconType.COMPUTER: {
      return (
        <Suspense fallback={<>loading...</>}>
          <Computer
            data-testid={IconType.COMPUTER}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
    default: {
      return (
        <Suspense fallback={<>loading...</>}>
          <NotFound
            data-testid={IconType.NOT_FOUND}
            color={color}
            width={width}
            height={height}
            onClick={action}
          />
        </Suspense>
      )
    }
  }
}
