import { Reducer } from 'redux'

import {
  HiringState,
  HiringTypes,
  ICardsResponse,
  ICheckout,
  IHome,
  IModality,
  IPlanSelected,
  IProposalResponse,
  IReceipt,
  IVipCardsResponse,
} from './types'

const INITIAL_STATE: HiringState = {
  home: {} as IHome,
  selectedModality: {} as IModality,
  selectedValue: 0,
  cards: {} as ICardsResponse,
  vipCards: {} as IVipCardsResponse,
  checkout: {} as ICheckout,
  proposal: {} as IProposalResponse,
  receipt: {} as IReceipt,
  checkedConsortiumInsurance: false,
  plan: {} as IPlanSelected,
}

const reducer: Reducer<HiringState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HiringTypes.SET_HOME:
      return { ...state, home: action.payload }
    case HiringTypes.SET_SELECTED_MODALITY:
      return { ...state, selectedModality: action.payload }
    case HiringTypes.SET_SELECTED_VALUE:
      return { ...state, selectedValue: action.payload }
    case HiringTypes.SET_CARDS:
      return { ...state, cards: action.payload }
    case HiringTypes.SET_VIP_CARDS:
      return { ...state, vipCards: action.payload }
    case HiringTypes.SET_CHECKOUT:
      return { ...state, checkout: action.payload }
    case HiringTypes.SET_PROPOSAL:
      return { ...state, proposal: action.payload }
    case HiringTypes.SET_RECEIPT:
      return { ...state, receipt: action.payload }
    case HiringTypes.SET_CHECKED_CONSORTIUM_INSURANCE:
      return { ...state, checkedConsortiumInsurance: action.payload }
    case HiringTypes.SET_PLAN:
      return { ...state, plan: action.payload }

    default:
      return state
  }
}

export default reducer
