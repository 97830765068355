import { Fragment } from 'react'

import { Div, PMedium } from 'src/styles/commons'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { uuid } from '@interco/lib-util'
import { InterUISeparator } from '@interco/inter-ui-react-lib'

import { FaqContent } from './FaqQuestions.styles'

export const FaqQuestions = () => {
  const theme = useTheme()
  const color = theme.colors.neutral.grayscale.A500

  const faqIcons = {
    help: <Icons.Help width={SIZE_MD} height={SIZE_MD} color={color} />,
    credit: <Icons.Credit width={SIZE_MD} height={SIZE_MD} color={color} />,
    receipt: <Icons.Receipt width={SIZE_MD} height={SIZE_MD} color={color} />,
    noTaxes: <Icons.NoTaxes width={SIZE_MD} height={SIZE_MD} color={color} />,
    star: <Icons.Star width={SIZE_MD} height={SIZE_MD} color={color} />,
    dollar: <Icons.Dollar width={SIZE_MD} height={SIZE_MD} color={color} />,
    profitability: <Icons.Profitability width={SIZE_MD} height={SIZE_MD} color={color} />,
  }

  const faqQuestionsData = [
    {
      id: uuid(),
      icon: faqIcons.help,
      question: 'Como funciona?',
      answer:
        'Todos os meses, o grupo paga um valor que é somado a uma poupança comum. Ao final do período contratado, o dinheiro é usado na aquisição do bem ou serviço de quem foi contemplado, mês a mês.',
    },
    {
      id: uuid(),
      icon: faqIcons.credit,
      question: 'Consórcio tem taxas?',
      answer:
        'O consórcio é uma modalidade de compra parcelada sem juros. A única cobrança é a taxa de administração dos serviços e a taxa de fundo de reserva.',
    },
    {
      id: uuid(),
      icon: faqIcons.receipt,
      question: 'Antecipação de contrato',
      answer:
        'Se você já tiver um dinheiro extra, pode usá-lo como lance para antecipar as parcelas do seu contrato.',
    },
    {
      id: uuid(),
      icon: faqIcons.star,
      question: 'Como ser contemplado antes?',
      answer:
        'Todos os meses acontecem as assembleias, onde as pessoas do grupo são contempladas por sorteio e por lance. Para ser contemplado antes, você pode dar uma lance vencedor e/ou ser o sorteado da vez.',
    },
    {
      id: uuid(),
      icon: faqIcons.dollar,
      question: 'Você receberá uma carta de crédito',
      answer:
        'Se for contemplado em um sorteio ou ao final do contrato, você receberá uma carta de crédito com o valor do seu bem ou serviço para efetuar a sua compra como se fosse uma compra à vista.',
    },
  ]

  return (
    <>
      {faqQuestionsData.map(({ id, icon, question, answer }, index) => (
        <Fragment key={id}>
          <FaqContent id={id} data-testid={`faq-${index}`}>
            {icon}
            <Div>
              <PMedium bold>{question}</PMedium>
              <PMedium marginBottom="0" scale={400}>
                {answer}
              </PMedium>
            </Div>
          </FaqContent>
          {index !== faqQuestionsData.length - 1 && (
            <InterUISeparator variant="small" height="1px" margin="24px 0" />
          )}
        </Fragment>
      ))}
    </>
  )
}
