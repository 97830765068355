import styled from 'styled-components'

export const PartnerLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`

export const PartnerLogoImg = styled.img`
  width: 152px;
  height: 47px;
`

export const CustomText = styled.div`
  color: ${(props) => props.theme.colors.neutral.grayscale.A500};
`
