import { HTMLAttributes } from 'react'

import { useSelector } from 'react-redux'
import { ICard } from 'src/store/ducks/hiring/types'
import { H2, H3, PMedium, PSmall } from 'src/styles/commons'
import { useTheme } from 'styled-components'
import { LoadingActions } from 'src/store/ducks/loading/actions'
import { LoadCardsVip } from 'src/components/Skeleton/components/LoadCardsVip/LoadCardsVip'
import { getIcon } from 'src/common/icons/icons'
import { Icons } from 'src/utils/icons'
import { SIZE_MD } from 'src/utils/constants'
import { productIconMap } from 'src/enums/ProductId'

import { formatToBRL } from '@interco/lib-util'

import { HeaderCard, BodyCard, Container } from './CardVip.styles'
import { Button } from '../Button/Button'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  cardDetails: ICard
  onClickedDetail: () => void
}

export const CardVip = ({ cardDetails, onClickedDetail, ...props }: CardProps) => {
  const theme = useTheme()

  const iconArrow = (
    <Icons.ChevronRight width={SIZE_MD} height={SIZE_MD} color={theme.colors.theme} />
  )

  const isLoading = useSelector(LoadingActions.get)

  const { productId, cardTitle, consortiumValue, installmentValue, term } = cardDetails

  return isLoading ? (
    <LoadCardsVip />
  ) : (
    <Container onClick={() => onClickedDetail()} {...props}>
      <HeaderCard>
        {getIcon(productIconMap[productId], {
          color: theme.colors.neutral.grayscale.A500,
          width: 32,
          height: 32,
        })}

        <PMedium margin="4px 0" scale={400}>
          {cardTitle}
        </PMedium>
        <H2 bold>{formatToBRL(consortiumValue)}</H2>
      </HeaderCard>

      <BodyCard>
        <PSmall marginBottom="4px">Primeira parcela por</PSmall>
        <H3 marginBottom="4px" bold>
          {`${formatToBRL(installmentValue)}/mês`}
        </H3>
        <PSmall marginBottom="16px">{`Em até ${term} meses`}</PSmall>

        <Button variant="primary" alignContent="space-between" small>
          <PMedium marginBottom="0px">Simular</PMedium> {iconArrow}
        </Button>
      </BodyCard>
    </Container>
  )
}
