import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { MockService } from 'src/services/mockService'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'

import { DeepLinkHandler } from './deepLinkHandler'

export function PathQueryParams() {
  const dispatch = useDispatch()
  const location = useLocation()

  const whitelist = ['/']
  const deepLinkHandler = DeepLinkHandler()
  let accessViaBannerOrDeepLink = false

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      dispatch(PathParamsActions.setUtmCampaignPathParams(location.search))

      const searchParams = new URLSearchParams(location.search)

      setPathParams(location.pathname, searchParams)
    }
    return accessViaBannerOrDeepLink
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    if (params.getAll.length > 0 && pathname === '/') {
      const allDeeplinkParams = [
        params.get('params') && `params=${params.get('params')}`,
        params.get('productid') && `productid=${params.get('productid')}`,
        params.get('subproductid') && `subproductid=${params.get('subproductid')}`,
        params.get('vparcela') && `vparcela=${params.get('vparcela')}`,
        params.get('vconsorcio') && `vconsorcio=${params.get('vconsorcio')}`,
        params.get('filtro') && `filtro=${params.get('filtro')}`,
        params.get('isVip') && `isVip=${params.get('isVip')}`,
        params.get('consortiumid') && `consortiumid=${params.get('consortiumid')}`,
        params.get('bankaccount') && `bankaccount=${params.get('bankaccount')}`,
      ]
        .filter(Boolean)
        .join('&')

      const deepParams = {
        params: params.get('params') || undefined,
      }

      const prefixParams = {
        prefix: params.get('prefix') || undefined,
      }

      const utmCampaignParams = {
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      const queryShouldMock = {
        shouldMock: 'true',
      }

      const queryAccount = {
        account: params.get('account') || undefined,
      }

      const queryApiKey = {
        apiKey: params.get('apiKey') || undefined,
      }

      if (queryShouldMock.shouldMock) {
        const shouldMock = queryShouldMock.shouldMock === 'true'
        MockService.prepareMock({
          shouldMock,
          account: queryAccount.account,
          apiKey: queryApiKey.apiKey,
        })
      }

      if (deepParams.params) {
        accessViaBannerOrDeepLink = true
        deepLinkHandler(`${allDeeplinkParams}`)
      }

      if (prefixParams.prefix) {
        dispatch(PathParamsActions.setPrefixPathParams(prefixParams.prefix))
      }

      if (utmCampaignParams.utm_campaign) {
        dispatch(PathParamsActions.setUtmCampaignPathParams(utmCampaignParams.utm_campaign))
      }
    }
  }

  return handler
}
